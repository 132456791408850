import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext, Axios } from './UserContext';
import {ReactComponent as Gear} from '../images/gear.svg';
import {ReactComponent as Icon1} from '../images/icon1.svg';
import {ReactComponent as Icon2} from '../images/icon2.svg';
import {ReactComponent as Icon3} from '../images/icon3.svg';
import {ReactComponent as Icon4} from '../images/icon4.svg';
import {ReactComponent as Icon5} from '../images/icon5.svg';
import {ReactComponent as Icon6} from '../images/icon6.svg';
import {ReactComponent as Icon7} from '../images/icon7.svg';
import {ReactComponent as Icon8} from '../images/icon8.svg';
import {ReactComponent as Icon9} from '../images/icon9.svg';
import {ReactComponent as Icon10} from '../images/icon10.svg';
import {ReactComponent as Icon11} from '../images/icon11.svg';
import LoadIcon from '../images/load.png';
import Carousel, { CarouselItem } from "./Carousel";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function Admin(){

    // --- Użytkownik i wylogowanie ---

    const {logout} = useContext(UserContext);

    // Przełącznik menu

    const [appLayer, setAppLayer] = useState(100);

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);

    // Zadania

    const [jobs, setJobs] = useState([]);

    // Pociągi

    const [trains, setTrains] = useState([]);

    // Nazwy pociągów

    const [trainNumbers, setTrainNumbers] = useState([]);

    // Stacje

    const [stations, setStations] = useState([]);

    // Dni pomiaru

    const [days, setDays] = useState([]);

    // Pomiary

    const [measurements, setMeasurements] = useState([]);

    // Użytkownicy

    const [users, setUsers] = useState([]);

    // Modale

    const [modalMessage, setModalMessage] = useState(null);

    const [modalErrorMessage, setModalErrorMessage] = useState(null);

    const [modal, setModal] = useState({
        show: false, 
        add_user: false,
        change_password: false,
        change_status: false,
        info: false,
        error: false,
        create_job: false,
        import_trains: false,
        show_photo: false,
        cameras_photos: false,
        cameras_photos_panel: false,
        ftp_info: false
    });

    function closeModal(){

        setModal({
            show: false, 
            add_user: false,
            change_password: false,
            change_status: false,
            info: false,
            error: false,
            create_job: false,
            import_trains: false,
            show_photo: false,
            cameras_photos: false,
            cameras_photos_panel: false,
            ftp_info: false
        });

    }

    function closeErrorModal(){

        const excludedProps = ['show', 'error'];

        const hasTrueProp = Object.entries(modal).some(([key, value]) => !excludedProps.includes(key) && value);

        if(hasTrueProp){

            setModal(prevModal => {return {...prevModal, error: false}});

        } else {

            setModal({
                show: false, 
                add_user: false,
                change_password: false,
                change_status: false,
                info: false,
                error: false,
                train_job: false,
                show_photo: false,
                cameras_photos: false,
                cameras_photos_panel: false,
                ftp_info: false
            });

        }

    }

    // Formatowanie daty

    function formatDate(dateString){

        const [year, month, day] = dateString.split("-");

        return `${day}/${month}/${year}`;

    }

    // Formatowanie czasu

    function formatTime(time){

        let hours = (Math.floor(time / 3600)).toString();

        if(hours.length === 1){

            hours = "0" + hours;

        }

        time %= 3600;

        let minutes = (Math.floor(time / 60)).toString();

        if(minutes.length === 1){

            minutes = "0" + minutes;

        }

        let seconds = (time % 60).toString();

        if(seconds.length === 1){

            seconds = "0" + seconds;

        }

        let timestring = hours + ":" + minutes + ":" + seconds;

        return timestring;

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Panel użytkowników

    // Wyznaczanie statusu - offline/online

    function getStatus(user) {

        const currentTime = new Date();

        const lastUpdate = new Date(user.last_update);

        let status;

        if(user.total_work_time_hours){

            const timeDiff = currentTime - lastUpdate;

            const threshold = 5 * 60 * 1000;
    
            status = timeDiff < threshold ? 'Online' : 'Offline';

        } else {

            status = 'Offline';

        }

        return <span className="user-status-info" style={{ color: status === 'Online' ? 'green' : 'red' }}>{status}</span>;

    }

    // Dodawanie nowego użytkownika

    const [userAddForm, setUserAddForm] = useState({
        add_username: '',
        add_password: '',
        full_name: '',
        phone: '',
        mail: '',
        rating: '',
        hour_rate: '',
        comment: ''
    });

    function userAddFormChange(event){

        const {name, value} = event.target;

        setUserAddForm(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    }

    const [formErrors, setFormErrors] = useState([]);

    function createUser(){

        const errors = [];

        let mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if(userAddForm.add_username === ''){
            errors.push('nazwa użytkownika nie może być pusta');
        }

        if(userAddForm.add_password === ''){
            errors.push('hasło nie może być puste');
        }

        if(userAddForm.add_password.length > 0 && userAddForm.add_password.length < 10){
            errors.push('hasło musi mieć co najmniej 10 znaków');
        }

        if(userAddForm.phone !== '' && userAddForm.phone.match(/\d/g).length !== 9){
            errors.push('numer telefonu musi mieć 9 cyfr');
        }

        if(userAddForm.mail !== '' && !userAddForm.mail.match(mailRegex)){
            errors.push('wprowadzono nieprawidłowy adres email');
        }

        if(errors.length > 0){

            setModal({...modal, show: true, error: true});

            setFormErrors(errors);

        } else {

            setFormErrors([]);

            let request_type = 'create user';

            let formData = userAddForm;

            Axios.post('classes/users.php', { formData, request_type }, { timeout: 10000 }).then(function(response){
                if(response.data === true){

                    setModal({...modal, show: true, add_user: false, info: true});

                    setModalMessage('Użytkownik został dodany.');

                    setUserAddForm({
                        add_username: '',
                        add_password: '',
                        full_name: '',
                        phone: '',
                        mail: '',
                        rating: '',
                        hour_rate: '',
                        comment: ''
                    });

                    getData();

                } else if(response.data.error){

                    setModal({...modal, show: true, error: true});

                    setModalErrorMessage(response.data.error);

                } else {

                    setModal({...modal, show: true, error: true});

                    setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

                }
            }).catch((error) => {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

                console.warn(error);

            });

        }

    }

    // Tabela użytkowników

    const [updatedUsers, setUpdatedUsers] = useState([]);

    useEffect(() => {

        // Usuwanie zduplikowanych pomiarów(zostawianie ostatniego pomiaru)

        const removeDuplicates = (arr) => {

            return Object.values(arr.reduce((acc, obj) => {

                const key = `${obj.job_number}-${obj.train_id}-${obj.station_id}`;
                
                if(!acc[key] || obj.id > acc[key].id){

                    acc[key] = obj;

                }
                
                return acc;

            }, {}));

        }

        if(users.length > 0 && jobs.length > 0 && measurements.length > 0){

            const current_users = [...users];

            current_users.forEach(user => {

                let personal_id = user.personal_id;

                const user_measurements = measurements.filter(item => item.personal_id === personal_id);

                const unique_user_measurements = removeDuplicates(user_measurements);

                const total_stages = unique_user_measurements.length;

                const total_counted = unique_user_measurements.reduce((sum, current) => {

                    const enteredSum = current.entered_sum >= 0 ? current.entered_sum : 0;

                    const exitedSum = current.exited_sum >= 0 ? current.exited_sum : 0;

                    return sum + enteredSum + exitedSum;

                }, 0);

                user.total_counted = total_counted;

                user.total_stages = total_stages;

                const user_jobs = jobs.filter(item => item.personal_id === personal_id);

                const user_finished_jobs = user_jobs.filter(item => item.status === 'zakończony' || item.status === 'zamknięty');

                const total_jobs = user_jobs.length;

                const finished_jobs = user_finished_jobs.length;

                user.total_jobs = total_jobs;

                user.finished_jobs = finished_jobs;

                const total_work_time_in_jobs = user_jobs.reduce((sum, current) => sum + current.work_time, 0);

                user.total_work_time_in_jobs = total_work_time_in_jobs;

                if(total_counted > 0 && total_stages > 0){

                    user.average_per_stage = Math.round(total_work_time_in_jobs/total_stages);

                    user.average_per_person = Math.round(total_work_time_in_jobs/total_counted);

                } else {

                    user.average_per_stage = 0;

                    user.average_per_person = 0;

                }

            });

            setUpdatedUsers(current_users);

        }

    }, [users, jobs, measurements]);

    const [userTable, setUserTable] = useState([]);

    const [userTableSorting, setUserTableSorting] = useState({attribute: 'personal_id', direction: 'ascending'});

    useEffect(() => {

        if(updatedUsers.length > 0){

            const attribute = userTableSorting.attribute;

            const direction = userTableSorting.direction;

            let type;

            if(attribute === 'surname' || attribute === 'personal_id' || attribute === 'status'){

                type = 'string';

            } else if(attribute === 'total_work_time' || attribute === 'total_work_time_in_jobs' || attribute === 'total_stages' || attribute === 'average_per_stage' || attribute === 'average_per_person' || attribute === 'total_jobs' || attribute === 'finished_jobs'){

                type = 'numerical';

            } else if(attribute === 'last_update'){

                type = 'date';

            }

            const array_of_objects = [...updatedUsers];

            let sorted;

            if(direction === 'ascending'){

                if(type === 'string'){

                    sorted = array_of_objects.sort((a, b) => {
                        return a[attribute].localeCompare(b[attribute]);
                    });

                } else if(type === 'numerical'){

                    sorted = array_of_objects.sort((a, b) => {
                        if (a[attribute] < b[attribute]) return -1;
                        if (a[attribute] > b[attribute]) return 1;
                        return 0;
                    });

                } else if(type === 'date'){

                    sorted = array_of_objects.sort((a, b) => {
                        return datetimeToDate(a[attribute]) - datetimeToDate(b[attribute]);
                    });

                }

            } else {

                if(type === 'string'){

                    sorted = array_of_objects.sort((a, b) => {
                        return b[attribute].localeCompare(a[attribute]);
                    });

                } else if(type === 'numerical'){

                    sorted = array_of_objects.sort((a, b) => {
                        if (a[attribute] < b[attribute]) return 1;
                        if (a[attribute] > b[attribute]) return -1;
                        return 0;
                    });

                } else if(type === 'date'){

                    sorted = array_of_objects.sort((a, b) => {
                        return datetimeToDate(b[attribute]) - datetimeToDate(a[attribute]);
                    });

                }

            }
            
            setUserTable(sorted);

        }

    }, [updatedUsers, userTableSorting]);

    function handleUserTableSorting(attribute){

        const current_attribute = userTableSorting.attribute;

        const current_direction = userTableSorting.direction;

        let new_direction;

        if(attribute === current_attribute){

            if(current_direction === 'descending'){

                new_direction = 'ascending';
    
            } else {
    
                new_direction = 'descending';
    
            }

        } else {

            new_direction = 'ascending';

        }

        setUserTableSorting({attribute: attribute, direction: new_direction});

    }

    const datetimeToDate = (datetime) => {
        return new Date(datetime);
    };

    // Pobieranie zestawienia wydajności pracowników

    const [modalSpin, setModalSpin] = useState(false);

    function getSummaryData(request_type, data){

        setModalMessage('Czekaj, trwa generowanie pliku.');

        setModalSpin(true);

        setModal({...modal, show: true, info: true});

        Axios.post('classes/data.php', { request_type, data }, { timeout: 10000 }).then(function(response){
            if(response.data.success){
                let filename = response.data.filename;
                downloadFile(filename);
                setModalMessage('');
                closeModal();
            } else {
                setModalMessage('Wystąpił błąd w trakcie generowania pliku. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
            }
            setModalSpin(false);
        }).catch((error) => {
            console.warn(error);
            setModalSpin(false);
            setModalMessage('Wystąpił błąd w trakcie generowania pliku. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
        });

    }

    function downloadFile(filename){
        const link = document.createElement('a');
        link.href = 'https://analiza.badaniaruchu.pl/downloads/'+filename;
        link.setAttribute('download', filename); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Zmiana hasła

    const [passwordChange, setPasswordChange] = useState({personal_id: null, new_password: '', full_name: null});

    function showPasswordChange(user){

        let personal_id = user.personal_id;

        let full_name = user.first_name + " " + user.surname;

        setPasswordChange({personal_id: personal_id, new_password: '', full_name: full_name});

        setModal({...modal, show: true, change_password: true});

    }

    function newPasswordChange(event){

        const {name, value} = event.target;

        setPasswordChange(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    }

    function changePassword(){

        const errors = [];

        if(passwordChange.new_password === ''){
            errors.push('hasło nie może być puste');
        }

        if(passwordChange.new_password.length > 0 && passwordChange.new_password.length < 10){
            errors.push('hasło musi mieć co najmniej 10 znaków');
        }

        if(errors.length > 0){

            setModal({...modal, show: true, error: true});

            setFormErrors(errors);

        } else {

            setFormErrors([]);

            let request_type = 'change password';

            let formData = passwordChange;

            Axios.post('classes/users.php', { formData, request_type }, { timeout: 10000 }).then(function(response){

                if(response.data === true){

                    setModal({...modal, show: true, change_password: false, info: true});

                    setModalMessage('Hasło zostało zmienione');

                } else if(response.data.error){

                    setModal({...modal, show: true, error: true});

                    setModalErrorMessage(response.data.error);

                } else {

                    setModal({...modal, show: true, error: true});

                    setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

                }

            }).catch((error) => {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

                console.warn(error);

            });

        }

    }

    const [showDeactivatedUsers, setShowDeactivatedUsers] = useState(false);

    const handleShowDeactivatedChange = () => {

        if(!showDeactivatedUsers){

            setShowDeactivatedUsers(true);

        } else {

            setShowDeactivatedUsers(false);

        }

    }

    // Deaktywacja / aktywacja użytkownika

    const [userToDeactivate, setUserToDeactivate] = useState({personal_id: null, new_status: null, full_name: null});

    function showUserDeactivation(user){

        let personal_id = user.personal_id;

        let full_name = user.first_name + " " + user.surname;

        let old_status = user.status;

        let new_status;

        if(old_status === 'active'){

            new_status = 'deactivated';

        } else {

            new_status = 'active';

        }

        setUserToDeactivate({personal_id: personal_id, new_status: new_status, full_name: full_name});

        setModal({...modal, show: true, change_status: true});

    }

    function changeUserActivation(){

        let formData = userToDeactivate;

        let request_type = 'change status';

        let result = userToDeactivate.new_status === "active" ? "aktywowany" : "deaktywowany";

        let success_message = 'Użytkownik został ' + result;

        Axios.post('classes/users.php', { formData, request_type }, { timeout: 10000 }).then(function(response){

            if(response.data === true){

                setModal({...modal, show: true, change_status: false, info: true});

                const current = [...users];

                const index = current.findIndex(user => user.personal_id === userToDeactivate.personal_id);

                current[index].status = userToDeactivate.new_status;

                setUsers(current);

                setModalMessage(success_message);

            } else if(response.data.error){

                setModal({...modal, show: true, error: true});

                setModalErrorMessage(response.data.error);

            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

        }).catch((error) => {

            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            console.warn(error);

        });

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Panel wydawania zadań

    // Pobieranie danych

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isInitialMount = useRef(true);

    useEffect(() => {

        if(isInitialMount.current){

            isInitialMount.current = false;

        } else {

            if(appLayer > 0 && appLayer < 300){

                getData();

            }

            if(appLayer === 400){

                getProgressData();

                if(tableRef.current){

                    tableRef.current.scrollTop = scrollPosition;
        
                    setScrollPosition(0);
        
                }

            }

            if(appLayer === 500){

                getJobsData();

                if(jobTableRef.current){

                    jobTableRef.current.scrollTop = jobScrollPosition;
        
                    setJobScrollPosition(0);
        
                }

            }

            if(appLayer === 610){

                getUsersWithJobsData();

            }

            if(appLayer === 620){

                getTrainViewData();

            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLayer]);

    function getData(){

        let request_type = 'get admin data';

        setSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){
                if(Array.isArray(response.data.trains)){
                    setTrains(response.data.trains);
                }
                if(Array.isArray(response.data.train_numbers)){
                    setTrainNumbers(response.data.train_numbers);
                }
                if(Array.isArray(response.data.stations)){
                    setStations(response.data.stations);
                }
                if(Array.isArray(response.data.recordings)){
                    setDays(response.data.recordings);
                }
                if(Array.isArray(response.data.jobs)){
                    setJobs(response.data.jobs);
                }
                if(Array.isArray(response.data.measurements)){
                    setMeasurements(response.data.measurements);
                }
                if(Array.isArray(response.data.users)){
                    setUsers(response.data.users);
                }
            } else {
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
            }
            setSearching(false);
        }).catch((error) => {
            console.warn(error);
            setSearching(false);
            setModal({...modal, show: true, info: true});
            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
        });

    }

    // Wyodrębnianie unikalnych dat

    const [uniqueDays, setUniqueDays] = useState([]);
    
    useEffect(() => {

        if(days.length > 0){

            const uniqueDatesObject = days.reduce((acc, obj) => {

                const { recording_date } = obj;

                if (!acc[recording_date]) {

                    acc[recording_date] = true;

                }

                return acc;

            }, {});
              
            const uniqueDatesArray = Object.keys(uniqueDatesObject);

            const sortedDates = uniqueDatesArray.sort();

            setUniqueDays(sortedDates);

            setStartDates(sortedDates);

            setEndDates(sortedDates);

        }
        
    }, [days]);

    // Daty początkowa i końcowa

    const [startDates, setStartDates] = useState([]);

    const [endDates, setEndDates] = useState([]);

    // Wyodrębnianie unikalnych stacji

    const [uniqueStations, setUniqueStations] = useState([]);

    useEffect(() => {

        if(days.length > 0 && stations.length > 0){
              
            const uniqueStationIdsObject = days.reduce((acc, obj) => {

                const { station_id } = obj;

                if (!acc[station_id]) {

                    acc[station_id] = true;

                }

                return acc;

            }, {});
              
            const uniqueStationIdsArray = Object.keys(uniqueStationIdsObject);

            const uniqueStationsWithNames = uniqueStationIdsArray.map(station_id => {

                const station = stations.find(station => station.station_id === station_id);

                return { station_id, station_name: station ? station.name : "-" };

            });

            const sortedStations = uniqueStationsWithNames.sort((a, b) => a.station_name.localeCompare(b.station_name));

            sortedStations.forEach(station => {

                let station_id = station.station_id;

                let station_name = station.station_name;

                if(station_id.startsWith("SW")){

                    let new_name = station_name + " - wakacje";

                    station.station_name = new_name;

                }

            });

            setUniqueStations(sortedStations);

        }
        
    }, [days, stations]);

    // Uzupełnianie pociągów o nazwy stacji i numer PKP

    useEffect(() => {

        if(trains.length > 0 && stations.length > 0 && trainNumbers.length > 0){ 

            trains.forEach(train => {

                const train_search = trainNumbers.find(u => u.train_id === train.train_id);

                if(train_search){

                    train.train_number = train_search.train_number;

                    const first_station = stations.find(u => u.station_id === train_search.first_station);

                    const last_station = stations.find(u => u.station_id === train_search.last_station);

                    if(first_station){

                        train.first_station_name = first_station.name;

                    }

                    if(last_station){

                        train.last_station_name = last_station.name;

                    }

                }

                const measurements_array = measurements.filter(item => item.train_id === train.train_id);

                train.measurements = measurements_array;

            });

        }
        
    }, [trains, stations, trainNumbers, measurements]);

    // Filtrowanie - data

    const [dateFilters, setDateFilters] = useState({start_date: '', end_date: ''});

    const latestDateFilters = useRef();

    useEffect(() => {

        latestDateFilters.current = dateFilters;

    }, [dateFilters]);

    // Wyodrębnianie minimalnej i maksymalnej daty i ustawianie domyślnego zakresu

    useEffect(() => {

        if(uniqueDays.length > 0){ 

            const dateObjects = uniqueDays.map(dateString => new Date(dateString));

            const maxDate = new Date(Math.max.apply(null, dateObjects));
            
            const minDate = new Date(Math.min.apply(null, dateObjects));

            const formattedMaxDate = maxDate.toISOString().split('T')[0];

            const formattedMinDate = minDate.toISOString().split('T')[0];

            if(latestDateFilters.current.start_date === '' && latestDateFilters.current.end_date === ''){

                setDateFilters({start_date: formattedMinDate, end_date: formattedMaxDate});

            }

        }

    }, [uniqueDays]);

    // Zmiana filtrów daty

    function onDateChange(e){

        const value = e.target.value;

        const name = e.target.name;

        let current_start = dateFilters.start_date;

        let current_end = dateFilters.end_date;

        // Jeśli zmieniona została data poczatkowa

        if(name === 'start_date'){

            const filteredDates = uniqueDays.filter(date => {

                return new Date(date) >= new Date(value);

            });

            setEndDates(filteredDates)

            if(new Date(current_end) < new Date(value)){

                current_end = value;

            }

            setDateFilters(prevFilters => {
                return {
                    ...prevFilters,
                    start_date: value,
                    end_date: current_end
                }
            });

        }

        // Jeśli została zmieniona data końcowa

        if(name === 'end_date'){

            const filteredDates = uniqueDays.filter(date => {

                return new Date(date) <= new Date(value);

            });

            setStartDates(filteredDates);

            if(new Date(current_start) > new Date(value)){

                current_start = value;

            }

            setDateFilters(prevFilters => {
                return {
                    ...prevFilters,
                    start_date: current_start,
                    end_date: value
                }
            });

        }

    }

    useEffect(() => {

        const currentfilteredStations = days.filter(item => {

            const recordingDate = new Date(item.recording_date);

            const start_date = new Date(dateFilters.start_date);

            const end_date = new Date(dateFilters.end_date);

            return recordingDate >= start_date && recordingDate <= end_date;

        }).map(item => item.station_id);

        const filteredUniqueStations = uniqueStations.filter(station => {
            
            return currentfilteredStations.includes(station.station_id);

        });

        setFilteredStations(filteredUniqueStations);

        if(filteredStations.length > 0 && !filteredUniqueStations.some(item => Object.values(item).includes(stationFilter))){

            setStationFilter(filteredUniqueStations[0].station_id);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilters]);

    // Filtrowanie - stacja

    const [stationFilter, setStationFilter] = useState();

    const latestStationFilter = useRef();

    useEffect(() => {

        latestStationFilter.current = stationFilter;

    }, [stationFilter]);

    const [filteredStations, setFilteredStations] = useState([]);

    function onStationChange(e){

        const value = e.target.value;

        setStationFilter(value);

    }

    useEffect(() => {

        if(uniqueStations.length > 0){ 

            if(!latestStationFilter.current){

                //setStationFilter(uniqueStations[0].station_id);

                setStationFilter('S029')

            }

            setFilteredStations(uniqueStations);

        }

    }, [uniqueStations]);

    // Filtrowanie - godzina

    const [time, setTime] = useState({start_hour: '00:00', end_hour: '23:59'});
    
    const handleTimeChange = (event) => {

        const {name, value} = event.target;

        setTime(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    };
    
    // Wyznaczanie pociągów dla wybranej stacji i zakresu godzin

    const [preFilteredTrains, setPreFilteredTrains] = useState([]);

    const [filteredTrains, setFilteredTrains] = useState([]);

    useEffect(() => {

        if(trains.length > 0 && stationFilter){

            let hour1 = new Date(new Date().toDateString() + ' ' + time.start_hour);

            let hour2 = new Date(new Date().toDateString() + ' ' + time.end_hour);

            let start_hour;

            let end_hour;

            if(hour1 > hour2){

                start_hour = hour2;

                end_hour = hour1;

            } else {

                start_hour = hour1;

                end_hour = hour2;

            }

            const filteredTrains = trains.filter(train => {

                const stationKeys = Object.keys(train).filter(key => key.startsWith('station_'));

                const stationMatch = stationKeys.find(key => train[key] === stationFilter);

                if(stationMatch){

                    let raw_index = stationMatch.split("_");

                    let index = raw_index[1];

                    const departure_time = train['departure_hour_'+index];

                    const arrival_time = train['arrival_hour_'+index];

                    const chosen_value = departure_time ? departure_time : arrival_time;

                    let compared_time = new Date(new Date().toDateString() + ' ' + chosen_value)

                    if(compared_time >= start_hour && compared_time <= end_hour){

                        train.station_index = index;

                        train.departure_time = departure_time;

                        train.arrival_time = arrival_time;

                        train.platform = train['platform_number_'+index];

                        train.station_id = stationFilter;

                        return true;

                    } else {

                        return false;

                    }

                } else {

                    return false;

                }

            });

            const sortedTrains = filteredTrains.sort((a, b) => {

                const timeA = a.departure_time || a.arrival_time;

                const timeB = b.departure_time || b.arrival_time;
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });

            setFilteredTrains(sortedTrains);

            // Wyznaczanie wszystkich pociągów dla danej stacji(bez filtru godzinowego)

            const filteredTrains2 = trains.filter(train => {

                const stationKeys = Object.keys(train).filter(key => key.startsWith('station_'));

                const stationMatch = stationKeys.find(key => train[key] === stationFilter);

                if(stationMatch){

                    let raw_index = stationMatch.split("_");

                    let index = raw_index[1];

                    const departure_time = train['departure_hour_'+index];

                    const arrival_time = train['arrival_hour_'+index];

                    train.station_index = index;

                    train.departure_time = departure_time;

                    train.arrival_time = arrival_time;

                    train.platform = train['platform_number_'+index];

                    train.station_id = stationFilter;

                    return true;

                } else {

                    return false;

                }

            });

            const sortedTrains2 = filteredTrains2.sort((a, b) => {

                const timeA = a.departure_time || a.arrival_time;

                const timeB = b.departure_time || b.arrival_time;
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });

            setPreFilteredTrains(sortedTrains2);

        }
        
    }, [stationFilter, time, trains]);

    const [outputTrains, setOutputTrains] = useState([]);

    useEffect(() => {

        if(filteredTrains.length > 0){

            filteredTrains.forEach(train => {

                const station = stations.find(station => station.station_id === train.station_id);

                const job_list = jobs.filter(item => item.train_id === train.train_id || item.station_id === train.station_id);

                const day_list = days.filter(item => item.station_id === train.station_id);

                train.jobs = job_list;

                train.station_name = station.name;

                train.days = day_list;
                
            });

            setOutputTrains(filteredTrains);

        } else {

            setOutputTrains([]);

        }
        
    }, [filteredTrains, days, jobs, stations]);

    // Wybór pociągów z tabeli

    const [selectedTrains, setSelectedTrains] = useState([]);

    const selectRow = (event) => {

        const train_id = parseInt(event.target.value);

        const current_selected = [...selectedTrains];

        if(current_selected.includes(train_id)){

            current_selected.splice(current_selected.indexOf(train_id), 1);

        } else {

            current_selected.push(train_id);

        }

        setSelectedTrains(current_selected);

    };

    const isTrainSelected = (id) => {

        return selectedTrains.includes(id);

    };

    const [isChecked, setIsChecked] = useState(true);

    const inverseSelection = () => {

        setIsChecked(prevChecked => !prevChecked);

        const current_ids = outputTrains.map(item => item.id);

        const current_selected = [...selectedTrains];

        current_ids.forEach(value => {

            if(current_selected.includes(value)){

                current_selected.splice(current_selected.indexOf(value), 1);

            } else {

                current_selected.push(value);

            }

        });

        setSelectedTrains(current_selected);

    };

    useEffect(() => {

        setSelectedTrains([]);

        setIsChecked(true);
        
    }, [stationFilter]);

    // Funkcje wyznaczania danych w tabeli

    function getJobCount(train){

        const train_id = train.train_id;

        const train_jobs = train.jobs;

        const train_hour = train.departure_time ? train.departure_time : train.arrival_time;

        const count = {};

        const all_days = train.days;

        if(all_days.length > 0){

            all_days.forEach(day => {

                let current_count = 0;

                const rec_date = day.recording_date;

                const match_jobs = train_jobs.filter(item => item.recording_date === rec_date && item.type === 'normal');

                if(match_jobs.length > 0){

                    match_jobs.forEach(job => {

                        if(!job.train_id){

                            if(!job.train_list){

                                let start_hour = job.start_hour;
    
                                let end_hour = job.end_hour;
    
                                if(train_hour >= start_hour && train_hour <= end_hour){

                                    current_count++;

                                    count[rec_date] = current_count;

                                } else {

                                    count[rec_date] = current_count;

                                }
    
                            } else {

                                const train_list = JSON.parse(job.train_list);

                                if(train_list.includes(train_id)){

                                    current_count++;

                                    count[rec_date] = current_count;

                                } else {

                                    count[rec_date] = current_count;

                                }

                            }

                        } else {

                            let job_date = job.recording_date;

                            if(job_date === rec_date){

                                current_count++;

                                count[rec_date] = current_count;

                            } else {

                                count[rec_date] = current_count;

                            }

                        }

                    });

                } else {

                    count[rec_date] = current_count;

                }

            });

        }

        const output_array = Object.keys(count).sort().map(key => {
            
            return { [key]: count[key] };

        });

        return (

            <>

                {output_array.map((item, index) => {

                    const propertyName = Object.keys(item)[0];

                    return (

                        <span key={index} className="train-number-split-main">{item[propertyName]}</span>

                    );

                })}

            </>

        );

    }

    function getMeasurementsResults(train, type){

        const jobs = train.jobs;

        const measurements = train.measurements;

        const measurements_data = measurements.filter(item => item.station_id === stationFilter);

        let measurements_count = measurements_data.length;

        if(measurements_count > 0){

            const reduced = measurements_data.reduce((acc, obj) => {

                const key = `${obj.personal_id}-${obj.job_number}`;
              
                if (!acc[key] || obj.id > acc[key].id) {

                    const job_check = jobs.find(item => item.job_number === obj.job_number);

                    if(job_check && job_check.type === 'normal'){

                        acc[key] = obj; 

                    }

                }
              
                return acc;

            }, {});

            const result = Object.values(reduced);

            const sorted = result.sort((a, b) => a.recording_date.localeCompare(b.recording_date));

            if(type){
    
                let property_name = type + "_sum";
    
                return (
    
                    <>
    
                        {sorted.map((item, index) => (
                            <span key={index} className="train-number-split-main">{item[property_name]}</span>
                        ))}
    
                    </>
    
                );

            } else {

                return (
    
                    <>
    
                        {sorted.map((item, index) => (
                            <span key={index} className="train-number-split-main">{formatDate(item.recording_date)}</span>
                        ))}
    
                    </>
    
                );

            }

        } else {

            return "-";

        }

    }

    // Wyznaczanie danych użytkowników do wykonanych pomiarów

    function getUserNames(train){

        const measurements = train.measurements;

        const measurements_data = measurements.filter(item => item.station_id === stationFilter);

        const jobs = train.jobs;

        const reduced = measurements_data.reduce((acc, obj) => {

            const key = `${obj.personal_id}-${obj.job_number}`;
          
            if (!acc[key] || obj.id > acc[key].id) {

                const job_check = jobs.find(item => item.job_number === obj.job_number);

                if(job_check && job_check.type === 'normal'){

                    acc[key] = obj;  

                }

            }
          
            return acc;

        }, {});

        const result = Object.values(reduced);

        const sorted = result.sort((a, b) => a.recording_date.localeCompare(b.recording_date));

        sorted.forEach(measurement => {

            const user_data = users.find(user => user.personal_id === measurement.personal_id);

            if(user_data){

                let user_name = user_data.first_name + " " + user_data.surname;

                measurement.full_name = user_name;

            }

        });

        if(sorted.length > 0){

            return  <p className="user-list-paragraph">
                    {sorted.map((item, index) => (
                        <span className="user-list-span" key={index}>{item.full_name}</span>
                    ))}
                </p>

        } else {

            return <span>-</span>;

        }

    }

    // Importowanie listy pociągów

    function showImportTrainList(){

        setModal({...modal, show: true, import_trains: true});

    }

    const [importListMessage, setImportListMessage] = useState(null);

    function importTrainList(){

        setImportListMessage(null);

        if(trainListImport === ''){

            return;

        }

        const all_trains = [...preFilteredTrains];

        const list_to_import = trainListImport;

        let list;

        if(!list_to_import.startsWith("[") || !list_to_import.endsWith("]")){

            list = "[" + list_to_import + "]";

        } else {

            list = list_to_import;

        }

        if(isValidJSON(list)){

            const array_of_trains = JSON.parse(list);

            const train_ids = all_trains.map(train => train.train_id);

            const train_ids_check = array_of_trains.every(element => train_ids.includes(element));

            if(!train_ids_check){

                const missing_ids = array_of_trains.filter(element => !train_ids.includes(element));

                let output = missing_ids.join(', ');

                let output_message = "Błędna lista pociągów - następujących numerów nie ma na liście dla bieżącej stacji: " + output + ".";

                setImportListMessage(output_message);

            } else {

                setTime({start_hour: "00:00", end_hour: "23:59"});

                const selection = all_trains.filter(obj => !array_of_trains.includes(obj.train_id)).map(obj => obj.id);

                setSelectedTrains(selection);

                closeModal();

            }

        } else {

            setImportListMessage('Niewłaściwy format danych');

        }

    }

    const [trainListImport, setTrainListImport] = useState('');

    function handleTrainListImport(e){

        let value = e.target.value;

        setTrainListImport(value);

    }

    function isValidJSON(str){

        try {

            JSON.parse(str);

            return true;

        } catch (e) {

            return false;

        }

    }

    // Tworzenie zadania

    const [jobToPrepare, setJobToPrepare] = useState({});

    const [dateToPrepare, setDateToPrepare] = useState("");

    const [latestUserData, setLatestUserData] = useState([]);

    const [latestJobsData, setLatestJobsData] = useState([]);

    function prepareJob(type, id, kind){

        if(kind === 'normal'){

            if(type === 'train'){

                const train = outputTrains.find(train => train.id === id);
    
                const days_list = train.days;
    
                const new_job_data = {
                    kind: kind,
                    type: type,
                    first_station_name: train.first_station_name,
                    last_station_name: train.last_station_name,
                    train_number: train.train_number,
                    train_id: train.train_id,
                    date: days_list
                }
        
                setJobToPrepare(new_job_data);
    
            } else if(type === 'station'){
    
                if(outputTrains.every(obj => selectedTrains.includes(obj.id))){
    
                    return;
    
                }
    
                const current_station = filteredStations.find(station => station.station_id === stationFilter);
    
                const days_list = days.filter(item => item.station_id === stationFilter);
    
                const new_job_data = {
                    kind: kind,
                    type: type,
                    station_id: current_station.station_id,
                    station_name: current_station.station_name,
                    date: days_list
                }
        
                setJobToPrepare(new_job_data);
    
            }
    
        } else if(kind === 'check'){

            // Przygotowanie z poziomu panelu realizacji

            if(id){

                if(detailedJobData.filter(obj => obj.measurements.length > 0).every(obj => selectedJobTrains.includes(obj.train_id))){
    
                    return;
    
                }
    
                const current_station_id = detailedJobInfo.station_id;
    
                const current_station_name = detailedJobInfo.station_name;
    
                const days_list = [{recording_date: detailedJobInfo.recording_date}];
    
                const new_job_data = {
                    kind: kind,
                    layer: 550,
                    type: type,
                    station_id: current_station_id,
                    station_name: current_station_name,
                    date: days_list
                }
        
                setJobToPrepare(new_job_data);

            // Przygotowanie z poziomu panelu postępu

            } else {

                if(updatedMeasurementData.filter(obj => obj.measurements.length > 0).every(obj => selectedMeasuredTrains.includes(obj.train_id))){
    
                    return;
    
                }
    
                const current_station_id = measurementSummaryData.station_id;
    
                const current_station_name = measurementSummaryData.station_name;
    
                const days_list = [{recording_date: measurementSummaryData.date}];
    
                const new_job_data = {
                    kind: kind,
                    layer: 450,
                    type: type,
                    station_id: current_station_id,
                    station_name: current_station_name,
                    date: days_list
                }
        
                setJobToPrepare(new_job_data);

            }

        }

        setAppLayer(350);
    
        getLatestUserJobsData();

    }

    function getLatestUserJobsData(){

        let request_type = 'get user jobs';

        setSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){

                if(Array.isArray(response.data.jobs)){

                    setLatestJobsData(response.data.jobs);

                } else {

                    setLatestJobsData([]);

                }

                if(Array.isArray(response.data.users)){

                    setLatestUserData(response.data.users);

                }

            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

            setSearching(false);

        }).catch((error) => {

            console.warn(error);

            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            setSearching(false);

        });

    }

    const [updatedUserJobsData, setUpdatedUserJobsData] = useState([]);

    useEffect(() => {

        if(latestUserData.length > 0){

            const update_data = [...latestUserData];

            if(latestJobsData.length > 0){

                update_data.forEach(user => {

                    const user_jobs = latestJobsData.filter(job => job.personal_id === user.personal_id);
    
                    let number_of_jobs = user_jobs.length;
    
                    let completed_jobs;
    
                    if(number_of_jobs > 0){
    
                        completed_jobs = user_jobs.filter(job => job.status === "zakończony" || job.status === 'zamknięty').length;
    
                    } else {
    
                        completed_jobs = 0;
    
                    }
    
                    user.number_of_jobs = number_of_jobs;
    
                    user.completed_jobs = completed_jobs;
    
                });
    
            } else {

                update_data.forEach(user => {
    
                    user.number_of_jobs = 0;
    
                    user.completed_jobs = 0;
    
                });

            }

            setUpdatedUserJobsData(update_data);

        } 

    }, [latestJobsData, latestUserData]);

    // Tworzenie zadania - wybór daty nagrania

    useEffect(() => {

        if(jobToPrepare.date && jobToPrepare.date.length > 0){

            setDateToPrepare(jobToPrepare.date[0].recording_date);

        }

    }, [jobToPrepare]);

    function onDatePrepareChange(e){

        const value = e.target.value;

        setDateToPrepare(value);

    }

    // Tworzenie zadania

    const [chosenUser, setChosenUser] = useState({});

    function prepareCreateJob(user){

        setChosenUser(user);

        setModal({...modal, show: true, create_job: true});

    }

    // Dodatkowy komentarz do zadania

    const [jobComment, setJobComment] = useState('');

    function handleComment(e){

        let value = e.target.value;

        setJobComment(value);

    }

    // Tworzenie zadania w bazie danych

    function createJob(){

        setIsChecked(true);

        let personal_id = chosenUser.personal_id;

        let type = jobToPrepare.type;

        let kind = jobToPrepare.kind;

        let train_id;

        let station_id;

        let start_hour;

        let end_hour;

        let ids_list;

        let train_list_count;

        if(kind === 'normal'){

            if(type === 'train'){

                train_id = jobToPrepare.train_id;
    
            }
    
            if(type === 'station'){
    
                station_id = jobToPrepare.station_id;
    
                if(selectedTrains.length === 0){
    
                    start_hour = time.start_hour;
    
                    end_hour = time.end_hour;
    
                } else {
    
                    const filtered = outputTrains.filter(item => !selectedTrains.includes(item.id));
    
                    train_list_count = filtered.length;
    
                    ids_list = JSON.stringify(filtered.map(item => item.train_id));
    
                }
    
            }

        } else if(kind === 'check'){

            station_id = jobToPrepare.station_id;

            if(jobToPrepare.layer === 550){

                const filtered = detailedJobData.filter(item => item.measurements.length > 0 && !selectedJobTrains.includes(item.train_id));
    
                train_list_count = filtered.length;

                ids_list = JSON.stringify(filtered.map(item => item.train_id));

            } else if(jobToPrepare.layer === 450){

                const filtered = updatedMeasurementData.filter(item => item.measurements.length > 0 && !selectedMeasuredTrains.includes(item.train_id));
    
                train_list_count = filtered.length;

                ids_list = JSON.stringify(filtered.map(item => item.train_id));

            }

        }

        let recording_date = dateToPrepare;

        let comments = jobComment;

        const job_data = {
            personal_id: personal_id,
            kind: kind,
            type: type,
            train_id: train_id,
            station_id: station_id,
            start_hour: start_hour,
            end_hour: end_hour,
            ids_list: ids_list,
            train_list_count: train_list_count,
            recording_date: recording_date,
            comments: comments
        }

        let request_type = 'create job';

        Axios.post('classes/job.php', { request_type, job_data }, { timeout: 5000 }).then(function(response){

            if(response.data === true){

                setModal({...modal, show: true, create_job: false, info: true});

                setModalMessage('Zadanie zostało utworzone.');

                if(kind === 'normal'){

                    setSelectedTrains([]);

                    setAppLayer(300);

                } else if(kind === 'check'){

                    setSelectedMeasuredTrains([]);

                    setAppLayer(400);

                }

                setJobToPrepare({});

                setJobComment('');

                getData();
                
            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

        }).catch((error) => {

            console.warn(error);
            
            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

        });

    }

    // Anulowanie tworzenia zadania

    function cancelPrepareJob(){

        let kind = jobToPrepare.kind

        setJobToPrepare({});

        if(kind === 'normal'){

            setAppLayer(300);

        } else {

            setAppLayer(jobToPrepare.layer);

        }

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Panel postępu

    const tableRef = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);

    const [progressJobs, setProgressJobs] = useState([]);

    const [progressStations, setProgressStations] = useState([]);

    const [progressDays, setProgressDays] = useState([]);

    const [progressUsers, setProgressUsers] = useState([]);

    function getProgressData(){

        let request_type = 'get progress data';

        setSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 20000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){
                if(Array.isArray(response.data.stations)){
                    setProgressStations(response.data.stations);
                }
                if(Array.isArray(response.data.recordings)){
                    setProgressDays(response.data.recordings);
                }
                if(Array.isArray(response.data.jobs)){
                    setProgressJobs(response.data.jobs);
                }
                if(Array.isArray(response.data.users)){
                    setProgressUsers(response.data.users);
                }
                setSearching(false);
            } else {
                setSearching(false);
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
            }
        }).catch((error) => {
            console.warn(error);
            setSearching(false);
            setModal({...modal, show: true, info: true});
            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
        });

    }

    // Daty początkowa i końcowa 

    const [progressFilters, setProgressFilters] = useState({start_date: '', end_date: '', status: '', check: ''});

    const [uniqueProgressDays, setUniqueProgressDays] = useState([]);

    const [progressStartDates, setProgressStartDates] = useState([]);

    const [progressEndDates, setProgressEndDates] = useState([]);

    useEffect(() => {

        if(progressDays.length > 0){

            const uniqueDatesObject = progressDays.reduce((acc, obj) => {

                const { recording_date } = obj;

                if (!acc[recording_date]) {

                    acc[recording_date] = true;

                }

                return acc;

            }, {});
              
            const uniqueDatesArray = Object.keys(uniqueDatesObject);

            const sortedDates = uniqueDatesArray.sort();

            setUniqueProgressDays(sortedDates);

            const dateObjects = sortedDates.map(dateString => new Date(dateString));

            const maxDate = new Date(Math.max.apply(null, dateObjects));
            
            const minDate = new Date(Math.min.apply(null, dateObjects));

            const formattedMaxDate = maxDate.toISOString().split('T')[0];

            const formattedMinDate = minDate.toISOString().split('T')[0];

            if(progressFilters.start_date === "" && progressFilters.end_date === ""){

                setProgressStartDates(sortedDates);

                setProgressEndDates(sortedDates);

                setProgressFilters({...progressFilters, start_date: formattedMinDate, end_date: formattedMaxDate});

            }

        }
        // eslint-disable-next-line
    }, [progressDays]);

    // Zmiana filtrów daty

    function onSelectedProgressDayChange(e){

        const value = e.target.value;

        const name = e.target.name;

        let current_start = progressFilters.start_date;

        let current_end = progressFilters.end_date;

        // Jeśli zmieniona została data poczatkowa

        if(name === 'start_date'){

            const filteredDates = uniqueProgressDays.filter(date => {

                return new Date(date) >= new Date(value);

            });

            setProgressEndDates(filteredDates)

            if(new Date(current_end) < new Date(value)){

                current_end = value;

            }

            setProgressFilters(prevFilters => {
                return {
                    ...prevFilters,
                    start_date: value,
                    end_date: current_end
                }
            });

        }

        // Jeśli została zmieniona data końcowa

        if(name === 'end_date'){

            const filteredDates = uniqueProgressDays.filter(date => {

                return new Date(date) <= new Date(value);

            });

            setProgressStartDates(filteredDates);

            if(new Date(current_start) > new Date(value)){

                current_start = value;

            }

            setProgressFilters(prevFilters => {
                return {
                    ...prevFilters,
                    start_date: current_start,
                    end_date: value
                }
            });

        }

        // Jeśli został zmieniony status lub stan kontroli

        if(name === 'status' || name === 'check'){

            setProgressFilters(prevFilters => {
                return {
                    ...prevFilters,
                    [name]: value
                }
            });

        }

    }

    const [updatedProgressData, setUpdatedProgressData] = useState([]);

    const [filteredProgressData, setFilteredProgressData] = useState([]);

    const [updatedProgressJobs, setUpdatedProgressJobs] = useState([]);

    const [uniqueProgressJobStatuses, setUniqueJobStatuses] = useState([]);

    const [uniqueProgressStatuses, setUniqueProgressStatuses] = useState([]);

    useEffect(() => {

        if(progressJobs.length > 0 && progressUsers.length > 0){

            const progress_jobs = [...progressJobs];

            progress_jobs.forEach(job => {

                const user_search = progressUsers.find(u => u.personal_id === job.personal_id);

                if(user_search){

                    job.user_full_name = user_search.first_name + " " + user_search.surname;

                }

            });

            setUpdatedProgressJobs(progress_jobs);

            const uniqueStatusesObject = progressJobs.reduce((acc, obj) => {

                const { status } = obj;

                if (!acc[status]) {

                    acc[status] = true;

                }

                return acc;

            }, {});
              
            const uniqueStatusesArray = Object.keys(uniqueStatusesObject);

            let index = uniqueStatusesArray.indexOf('wydany');

            if(index !== -1){

                uniqueStatusesArray[index] = 'nierozpoczęty';

            }

            const sortedStatuses = uniqueStatusesArray.sort();

            setUniqueJobStatuses(sortedStatuses);

        }
        
    }, [progressJobs, progressUsers]);

    useEffect(() => {

        if(uniqueProgressJobStatuses.length > 0 && updatedProgressData.length > 0){

            const statuses = [...uniqueProgressJobStatuses];

            if(updatedProgressData.some(obj => obj.normal_jobs.length === 0)){

                statuses.unshift('niewydany');

                setUniqueProgressStatuses(statuses);

            } else {

                setUniqueProgressStatuses(statuses);

            }

        }
        
    }, [uniqueProgressJobStatuses, updatedProgressData]);

    useEffect(() => {

        if(progressDays.length > 0 && progressStations.length > 0 && updatedProgressJobs.length > 0){

            const updated = [...progressDays]

            updated.forEach(day => {

                const station_search = progressStations.find(u => u.station_id === day.station_id);

                if(station_search){

                    day.station_name = station_search.name;

                }

                const normal_jobs_array = updatedProgressJobs.filter(item => item.station_id === day.station_id && item.recording_date === day.recording_date && item.type === 'normal');

                const check_jobs_array = updatedProgressJobs.filter(item => item.station_id === day.station_id && item.recording_date === day.recording_date && item.type === 'check');

                day.normal_jobs = normal_jobs_array;

                day.check_jobs = check_jobs_array;

            });

            setUpdatedProgressData(updated);

        }
        
    }, [progressDays, progressStations, updatedProgressJobs]);

    useEffect(() => {

        if(updatedProgressData.length > 0){

            const start_date = new Date(progressFilters.start_date);

            const end_date = new Date(progressFilters.end_date);

            function status(item){

                if(progressFilters.status === ''){

                    return true;

                }

                if(progressFilters.status === "niewydany"){

                    return item.normal_jobs.length === 0;
    
                }

                let status = progressFilters.status === 'nierozpoczęty' ? 'wydany' : progressFilters.status;

                return item.normal_jobs.some(job => job.status === status) || item.check_jobs.some(job => job.status === status);
    
            }

            function check(item){

                if(progressFilters.check === ""){

                    return true;

                }

                if(progressFilters.check === "yes"){

                    return item.check_jobs.length > 0;

                }

                if(progressFilters.check === "no"){

                    return item.check_jobs.length === 0;

                }

            }

            const all_data = [...updatedProgressData];

            const current_filtered = all_data.filter(item => {

                const recording_date = new Date(item.recording_date);

                return recording_date >= start_date && recording_date <= end_date && status(item) && check(item);

            });

            setFilteredProgressData(current_filtered);

        }
        
    }, [updatedProgressData, progressFilters]);



    ///////////////////////////////////////

    // Podgląd zadań

    const [jobsData, setJobsData] = useState([]);

    const [userData, setUserData] = useState([]);

    const [measurementsData, setMeasurementsData] = useState([]);

    function getJobsData(){

        let request_type = 'get jobs data';

        setSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){

                if(Array.isArray(response.data.jobs)){

                    setJobsData(response.data.jobs);

                } 

                if(Array.isArray(response.data.users)){

                    setUserData(response.data.users);

                }

                if(Array.isArray(response.data.measurements)){

                    setMeasurementsData(response.data.measurements);

                }

            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

            setSearching(false);

        }).catch((error) => {

            console.warn(error);

            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            setSearching(false);

        });

    }

    // Łączenie danych na temat zadań

    const [updatedJobsData, setUpdatedJobsData] = useState([]);

    useEffect(() => {

        if(jobsData.length > 0 && userData.length > 0 && stations.length > 0 && trains.length > 0 && trainNumbers.length > 0){

            const update_data = [...jobsData];

            update_data.forEach(job => {

                const user_data = userData.find(user => user.personal_id === job.personal_id);

                job.full_name = user_data.first_name + " " + user_data.surname;

                if(job.station_id){

                    const station_data = stations.find(stations => stations.station_id === job.station_id);

                    job.station_name = station_data.name;

                }

                if(job.train_id){

                    const train_number_data = trainNumbers.find(train => train.train_id === job.train_id);

                    const first_station_name = stations.find(station => station.station_id === train_number_data.first_station);

                    const last_station_name = stations.find(station => station.station_id === train_number_data.last_station);

                    job.train_number = train_number_data.train_number;

                    job.first_station_name = first_station_name.name;

                    job.last_station_name = last_station_name.name;

                }

                const dateOnly = job.creation_date.split(' ')[0];

                job.created_on = dateOnly;

            });
    
            setUpdatedJobsData(update_data);

        } 

    }, [jobsData, userData, measurementsData, stations, trains, trainNumbers]);

    // Filtry widoku zadań

    ///////////////////////////////////////////////////////////////////////////

    // Unikalne daty sposród wszystkich zadań

    const [uniqueJobsDays, setUniqueJobsDays] = useState([]);

    // Daty początkowa i końcowa

    const [startJobsDates, setStartJobsDates] = useState([]);

    const [endJobsDates, setEndJobsDates] = useState([]);

    // Aktualne filtry - daty początkowej i końcowej

    const [dateJobsFilters, setDateJobsFilters] = useState({job_start_date: '', job_end_date: ''});

    // Wyodrębnianie minimalnej i maksymalnej daty i ustawianie domyślnego zakresu

    const [dateRange, setDateRange] = useState({min: "", max: ""});

    useEffect(() => {

        if(uniqueJobsDays.length > 0){ 

            const dateObjects = uniqueJobsDays.map(dateString => new Date(dateString));

            const maxDate = new Date(Math.max.apply(null, dateObjects));
            
            const minDate = new Date(Math.min.apply(null, dateObjects));

            const formattedMaxDate = maxDate.toISOString().split('T')[0];

            const formattedMinDate = minDate.toISOString().split('T')[0];

            setDateRange({min: formattedMinDate, max: formattedMaxDate});

            setDateJobsFilters({job_start_date: formattedMinDate, job_end_date: formattedMaxDate});

        }
        
    }, [uniqueJobsDays]);

    // Zmiana filtrów daty

    function onJobsDateChange(e){

        const value = e.target.value;

        const name = e.target.name;

        let current_start = dateJobsFilters.job_start_date;

        let current_end = dateJobsFilters.job_end_date;

        // Jeśli zmieniona została data poczatkowa

        if(name === 'job_start_date'){

            const filteredDates = uniqueJobsDays.filter(date => {

                return new Date(date) >= new Date(value);

            });

            setEndJobsDates(filteredDates)

            if(new Date(current_end) < new Date(value)){

                current_end = value;

            }

            setDateJobsFilters(prevFilters => {
                return {
                    ...prevFilters,
                    job_start_date: value,
                    job_end_date: current_end
                }
            });

        }

        // Jeśli została zmieniona data końcowa

        if(name === 'job_end_date'){

            const filteredDates = uniqueJobsDays.filter(date => {

                return new Date(date) <= new Date(value);

            });

            setStartJobsDates(filteredDates);

            if(new Date(current_start) > new Date(value)){

                current_start = value;

            }

            setDateJobsFilters(prevFilters => {
                return {
                    ...prevFilters,
                    job_start_date: current_start,
                    job_end_date: value
                }
            });

        }

    }

    ///////////////////////////////////////////////////////////////////////////

    // Unikalne stacje występujące w zadaniu

    const [uniqueJobsStations, setUniqueJobsStations] = useState([]);

    // Filtrowanie - stacja

    const [jobsStationFilter, setJobsStationFilter] = useState('');

    const [filteredJobsStations, setFilteredJobsStations] = useState([]);

    function onJobsStationChange(e){

        const value = e.target.value;

        setJobsStationFilter(value);

    }

    // Wyodrębnianie unikalnych stacji

    useEffect(() => {

        if(updatedJobsData.length > 0 && stations.length > 0){
            
            const uniqueStationIdsObject = updatedJobsData.reduce((acc, obj) => {

                const { station_id } = obj;

                if (station_id && !acc[station_id]) {

                    acc[station_id] = true;
                }

                return acc;

            }, {});
            
            const uniqueStationIdsArray = Object.keys(uniqueStationIdsObject);

            const uniqueStationsWithNames = uniqueStationIdsArray.map(station_id => {

                const station = stations.find(station => station.station_id === station_id);

                let station_name;

                if(station){

                    if(station_id.startsWith("SW")){

                        station_name = station.name + " - wakacje";

                    } else {

                        station_name = station.name;

                    }

                } else {

                    station_name = "-";

                }

                return { station_id, station_name: station_name };

            });

            const sortedStations = uniqueStationsWithNames.sort((a, b) => a.station_name.localeCompare(b.station_name));

            setUniqueJobsStations(sortedStations);

            setFilteredJobsStations(sortedStations);

        }
        
    }, [updatedJobsData, stations]);

    ///////////////////////////////////////////////////////////////////////////

    // Filtrowanie - pomiarowiec

    const [uniqueJobsUsers, setUniqueJobsUsers] = useState([]);

    const [filteredJobsUsers, setFilteredJobsUsers] = useState([]);

    const [jobsUserFilter, setJobsUserFilter] = useState("");

    function onJobUserChange(e){

        const value = e.target.value;

        setJobsUserFilter(value);

    }

    ///////////////////////////////////////////////////////////////////////////

    // Data wydania zadania

    const [uniqueJobsScheduleDays, setUniqueJobsScheduleDays] = useState([]);

    const [filteredJobsScheduleDays, setFilteredJobsScheduleDays] = useState([]);

    const [scheduleDateJobsFilters, setScheduleDateJobsFilters] = useState("");

    function onScheduleJobsDateChange(e){

        const value = e.target.value;

        setScheduleDateJobsFilters(value);

    }

    ///////////////////////////////////////////////////////////////////////////

    // Rodzaje zadań

    const [filteredJobsTypes, setFilteredJobsTypes] = useState([]);

    // Statusy zadań

    const [filteredJobsStatuses, setFilteredJobsStatuses] = useState([]);

    // Filtrowanie - rodzaj zadania i stan wykonania

    const [jobStatusFilter, setJobStatusFilter] = useState({job_type: '', job_status: ''});

    function onjobStatusFilterChange(event){

        const {name, value} = event.target;

        setJobStatusFilter(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    ///////////////////////////////////////////////////////////////////////////

    // Wyodrębnianie początkowych unikalnych wartości spośród wszystkich zadań
    
    useEffect(() => {

        if(updatedJobsData.length > 0){

            // Wyodrębnianie unikalnych dat

            const uniqueDatesObject = updatedJobsData.reduce((acc, obj) => {

                const { recording_date } = obj;

                if (!acc[recording_date]) {

                    acc[recording_date] = true;

                }

                return acc;

            }, {});
              
            const uniqueDatesArray = Object.keys(uniqueDatesObject);

            const sortedDates = uniqueDatesArray.sort();

            setUniqueJobsDays(sortedDates);

            setStartJobsDates(sortedDates);

            setEndJobsDates(sortedDates);

            // Wyodrębnianie unikalnych pomiarowców

            const uniqueUsersObject = updatedJobsData.reduce((acc, obj) => {

                const { full_name } = obj;

                if (!acc[full_name]) {

                    acc[full_name] = true;
                }

                return acc;

            }, {});

            const uniqueUsersArray = Object.keys(uniqueUsersObject);
              
            const sortedUniqueUsers = uniqueUsersArray.sort((a, b) => {

                const lastNameA = a.split(' ')[1];

                const lastNameB = b.split(' ')[1];

                return lastNameA.localeCompare(lastNameB);

            });

            setUniqueJobsUsers(sortedUniqueUsers);

            setFilteredJobsUsers(sortedUniqueUsers);

            // Wyodrębnianie unikalnych dat wydania zadań

            const uniqueDatesArray2 = Object.keys(updatedJobsData.reduce((acc, obj) => {

                const dateOnly = obj.creation_date.split(' ')[0];

                if (!acc[dateOnly]) {
                    acc[dateOnly] = true;
                }

                return acc;

            }, {}));

            const sortedDates2 = uniqueDatesArray2.sort();

            setUniqueJobsScheduleDays(sortedDates2);

            setFilteredJobsScheduleDays(sortedDates2);

            // Wyodrębnianie unikalnych rodzajów zadań

            const unique_types = getUniqueTypes(updatedJobsData);

            setFilteredJobsTypes(unique_types)

            // Wyodrębnianie unikalnych statusów

            const unique_statuses = getUniqueStatuses(updatedJobsData);

            unique_statuses.sort((a, b) => {
                if (a.status_name < b.status_name) {
                  return -1;
                }
                if (a.status_name > b.status_name) {
                  return 1;
                }
                return 0;
            });

            setFilteredJobsStatuses(unique_statuses);

        }
        
    }, [updatedJobsData]);

    ///////////////////////////////////////////////////////////////////////////

    // Wyodrębnianie unikalnych rodzajów zadań

    const getUniqueTypes = (inputArray) => {

        const uniqueTypes = inputArray.reduce((acc, obj) => {
            if (!acc.includes(obj.type)) {
                acc.push(obj.type);
            }
            return acc;
        }, []);

        return uniqueTypes.map(type => {
            if (type === 'normal') {
                return { type_name: 'Zwykłe', type_value: 'normal' };
            } else if (type === 'check') {
                return { type_name: 'Kontrolne', type_value: 'check' };
            } else {
                return null;
            }
        });

    };

    ///////////////////////////////////////////////////////////////////////////

    // wyodrębnianie unikalnych statusów zadań

    const getUniqueStatuses = (inputArray) => {

        const uniqueStatuses = inputArray.reduce((acc, obj) => {
            if (!acc.includes(obj.status)) {
                acc.push(obj.status);
            }
            return acc;
        }, []);

        return uniqueStatuses.map(status => {
            if (status === 'wydany') {
                return { status_name: 'nierozpoczęte', status_value: 'wydany' };
            } else if (status === 'rozpoczęty') {
                return { status_name: 'rozpoczęte', status_value: 'rozpoczęty' };
            } else if (status === 'zakończony') {
                return { status_name: 'ukończone', status_value: 'zakończony' };
            } else if (status === 'zamknięty') {
                return { status_name: 'zamknięte', status_value: 'zamknięty' };
            } else {
                return null;
            }
        });
        
    };

    ///////////////////////////////////////////////////////////////////////////

    // Wyodrębnianie aktualnej listy zadań według zastosowanych filtrów

    const [filteredJobsData, setFilteredJobsData] = useState([]);

    useEffect(() => {

        if(updatedJobsData.length > 0){

            const filteredData = updatedJobsData.filter(job => {

                const isRecordingDateValid = (!dateJobsFilters.job_start_date || !dateJobsFilters.job_end_date) ||
                    (new Date(job.recording_date) >= new Date(dateJobsFilters.job_start_date) && new Date(job.recording_date) <= new Date(dateJobsFilters.job_end_date));

                const isStationIdValid = !jobsStationFilter || job.station_id === jobsStationFilter;

                const isFullNameValid = !jobsUserFilter || job.full_name === jobsUserFilter;

                const isCreatedOnValid = !scheduleDateJobsFilters || job.created_on === scheduleDateJobsFilters;

                const isTypeValid = !jobStatusFilter.job_type || job.type === jobStatusFilter.job_type;

                const isStatusValid = !jobStatusFilter.job_status || job.status === jobStatusFilter.job_status;

                return isRecordingDateValid && isStationIdValid && isFullNameValid && isCreatedOnValid && isTypeValid && isStatusValid;

            });

            setFilteredJobsData(filteredData);



        }

    }, [updatedJobsData, dateJobsFilters, jobsStationFilter, jobsUserFilter, scheduleDateJobsFilters, jobStatusFilter]);

    useEffect(() => {

        if(filteredJobsData.length > 0){

            // Wyodrębnianie unikalnych dat

            const uniqueDatesObject = filteredJobsData.reduce((acc, obj) => {

                const { recording_date } = obj;

                if (!acc[recording_date]) {

                    acc[recording_date] = true;

                }

                return acc;

            }, {});
              
            const uniqueDatesArray = Object.keys(uniqueDatesObject);

            const sortedDates = uniqueDatesArray.sort();

            setStartJobsDates(sortedDates);

            setEndJobsDates(sortedDates);

            // Wyodrębnianie stacji

            const uniqueStationIdsObject = filteredJobsData.reduce((acc, obj) => {

                const { station_id } = obj;

                if (station_id && !acc[station_id]) {

                    acc[station_id] = true;

                }

                return acc;

            }, {});
            
            const uniqueStationIdsArray = Object.keys(uniqueStationIdsObject);

            const uniqueStationsWithNames = uniqueStationIdsArray.map(station_id => {

                const station = stations.find(station => station.station_id === station_id);

                let station_name;

                if(station){

                    if(station_id.startsWith("SW")){

                        station_name = station.name + " - wakacje";

                    } else {

                        station_name = station.name;

                    }

                } else {

                    station_name = "-";

                }

                return { station_id, station_name: station_name };

            });

            const sortedStations = uniqueStationsWithNames.sort((a, b) => a.station_name.localeCompare(b.station_name));

            setFilteredJobsStations(sortedStations);

            // Wyodrębnianie unikalnych pomiarowców

            const uniqueUsersObject = filteredJobsData.reduce((acc, obj) => {

                const { full_name } = obj;

                if (!acc[full_name]) {

                    acc[full_name] = true;
                }

                return acc;

            }, {});

            const uniqueUsersArray = Object.keys(uniqueUsersObject);
              
            const sortedUniqueUsers = uniqueUsersArray.sort((a, b) => {

                const lastNameA = a.split(' ')[1];

                const lastNameB = b.split(' ')[1];

                return lastNameA.localeCompare(lastNameB);

            });

            setFilteredJobsUsers(sortedUniqueUsers);

            // Wyodrębnianie unikalnych dat wydania zadań

            const uniqueDatesArray2 = Object.keys(filteredJobsData.reduce((acc, obj) => {

                const dateOnly = obj.creation_date.split(' ')[0];

                if (!acc[dateOnly]) {
                    acc[dateOnly] = true;
                }

                return acc;

            }, {}));

            const sortedDates2 = uniqueDatesArray2.sort();

            setFilteredJobsScheduleDays(sortedDates2);

            // Wyodrębnianie unikalnych rodzajów zadań

            const unique_types = getUniqueTypes(filteredJobsData);

            setFilteredJobsTypes(unique_types);

            // Wyodrębnianie unikalnych statusów

            const unique_statuses = getUniqueStatuses(filteredJobsData);

            unique_statuses.sort((a, b) => {
                if (a.status_name < b.status_name) {
                  return -1;
                }
                if (a.status_name > b.status_name) {
                  return 1;
                }
                return 0;
            });

            setFilteredJobsStatuses(unique_statuses);

        }

    }, [filteredJobsData, stations]);

    function clearAllFilters(){
        setDateJobsFilters({job_start_date: dateRange.min, job_end_date: dateRange.max});
        setJobsStationFilter('');
        setJobsUserFilter('');
        setScheduleDateJobsFilters('');
        setJobStatusFilter({job_type: '', job_status: ''});
    }

    // Wyliczenie liczby pomiarów 

    function getMeasurementsCount(train){

        const measurements = train.measurements;

        const measurements_data = measurements.filter(item => item.station_id === stationFilter);

        const jobs = train.jobs;

        const count = measurements_data.reduce((acc, obj) => {

            const job_check = jobs.find(item => item.job_number === obj.job_number);

            if(job_check && job_check.type === 'normal'){

                acc[obj.recording_date] = (acc[obj.recording_date] || 0) + 1;

            }
          
            return acc;

        }, {});

        const result = Object.entries(count).map(([date, count]) => ({
            recording_date: date,
            count: count
        }));

        const sorted = result.sort((a, b) => a.recording_date.localeCompare(b.recording_date));

        if(sorted.length > 0){

            return (

                <>

                    {result.map((item, index) => (
                        <span key={index} className="train-number-split-main">{item.count}</span>
                    ))}

                </>

            );

        } else {

            return "-";

        }

    }

    // Wyświetlanie szczegółów zadania - 450

    const [measurementSummaryData, setMeasurementSummaryData] = useState({date: null, measurements: [], trains: [], station_id: null, station_name: null});

    function showDetailedPoint(id){

        setIsMeasuredTrainChecked(true);

        setSelectedMeasuredTrains([]);

        const data = filteredProgressData.find(obj => obj.id === id);

        const jobs_data = data.normal_jobs;

        const train_list = [];

        const station_id = data.station_id;

        const station_data = stations.find(station => station.station_id === station_id);

        const station_name = station_data.name;

        const recording_date = data.recording_date

        jobs_data.forEach(job => {

            if(job.train_list){

                train_list.push(...JSON.parse(job.train_list));

            } else {

                let hour1 = new Date(new Date().toDateString() + ' ' + time.start_hour);

                let hour2 = new Date(new Date().toDateString() + ' ' + time.end_hour);

                let start_hour;

                let end_hour;

                if(hour1 > hour2){

                    start_hour = hour2;

                    end_hour = hour1;

                } else {

                    start_hour = hour1;

                    end_hour = hour2;

                }

                const filteredTrains = trains.filter(train => {

                    const stationKeys = Object.keys(train).filter(key => key.startsWith('station_'));
    
                    const stationMatch = stationKeys.find(key => train[key] === station_id);
    
                    if(stationMatch){
    
                        let raw_index = stationMatch.split("_");
    
                        let index = raw_index[1];
    
                        const departure_time = train['departure_hour_'+index];
    
                        const arrival_time = train['arrival_hour_'+index];
    
                        const chosen_value = departure_time ? departure_time : arrival_time;
    
                        let compared_time = new Date(new Date().toDateString() + ' ' + chosen_value)
    
                        if(compared_time >= start_hour && compared_time <= end_hour){
    
                            train.station_index = index;
    
                            train.departure_time = departure_time;
    
                            train.arrival_time = arrival_time;
    
                            train.platform = train['platform_number_'+index];
    
                            train.station_id = stationFilter;
    
                            return true;
    
                        } else {
    
                            return false;
    
                        }
    
                    } else {
    
                        return false;
    
                    }
    
                });

                const arr = filteredTrains.map(item => item.train_id);

                train_list.push(...arr);

            }

        });

        const unique_trains = [...new Set(train_list)];

        const unique_array = unique_trains.map(value => ({ train_id: value }));
        
        let request_type = 'get measurements data';

        setSearching(true);

        Axios.post('classes/data.php', { request_type, station_id, recording_date, unique_trains }, { timeout: 10000 }).then(function(response){
            if(response.data.measurements){

                setScrollPosition(tableRef.current.scrollTop);

                setAppLayer(450);

                setMeasurementSummaryData({date: recording_date, measurements: response.data.measurements, trains: unique_array, station_id: station_id, station_name: station_name});

            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

            setSearching(false);

        }).catch((error) => {

            console.warn(error);

            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            setSearching(false);

        });

    }

    useEffect(() => {

        if(measurementSummaryData.trains.length > 0){

            const trains_data = [...measurementSummaryData.trains];

            const measurements_data = [...measurementSummaryData.measurements];

            const station_id = measurementSummaryData.station_id;

            const station_name = measurementSummaryData.station_name;

            let unique_measurements;

            if(measurements_data.length > 0){

                unique_measurements = Array.from(
                    measurements_data.reduce((map, obj) => {
                        const key = `${obj.train_id}-${obj.job_number}`;
                        if (!map.has(key) || map.get(key).id < obj.id) {
                            map.set(key, obj);
                        }
                        return map;
                    }, new Map()).values()
                );
    
            } else {

                unique_measurements = measurements_data;

            }

            trains_data.forEach(train => {

                const train_match = trains.find(t => t.train_id === train.train_id);

                if(train_match){

                    const stationKeys = Object.keys(train_match).filter(key => key.startsWith('station_'));

                    const stationMatch = stationKeys.find(key => train_match[key] === station_id);

                    if(stationMatch){

                        let raw_index = stationMatch.split("_");
    
                        let index = raw_index[1];
    
                        const departure_time = train_match['departure_hour_'+index];
    
                        const arrival_time = train_match['arrival_hour_'+index];
    
                        train.station_index = index;
    
                        train.departure_time = departure_time;
    
                        train.arrival_time = arrival_time;
    
                        train.platform = train_match['platform_number_'+index];

                        train.relation = train_match.first_station_name + " - " + train_match.last_station_name;

                        train.train_number = train_match.train_number;

                    }

                }

                const train_measurements = unique_measurements.filter(item => item.train_id === train.train_id);

                train_measurements.forEach(measurement => {

                    const user_match = users.find(user => user.personal_id === measurement.personal_id);

                    const job_match = jobs.find(job => job.job_number === measurement.job_number);

                    if(job_match){

                        measurement.job_type = job_match.type;

                    }

                    if(user_match){

                        measurement.full_name = user_match.first_name + " " + user_match.surname;

                    }

                });

                train.measurements = train_measurements;

                train.station_name = station_name;

            });

            const sorted = trains_data.sort((a, b) => {

                const timeA = a.departure_time || a.arrival_time;

                const timeB = b.departure_time || b.arrival_time;
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });

            setUpdatedMeasurementData(sorted);

        } 

    }, [measurementSummaryData, trains, stations, users, jobs]);

    const [updatedMeasurementData, setUpdatedMeasurementData] = useState([]);   

    const [outputMeasurementSummaryData, setOutputMeasurementSummaryData] = useState([]);

    const [measurementSummarySorting, setMeasurementSummarySorting] = useState({attribute: null, direction: null})

    useEffect(() => {

        if(updatedMeasurementData.length > 0){

            const current = [...updatedMeasurementData];

            if(measurementSummarySorting.attribute){

                let attribute = measurementSummarySorting.attribute;

                let direction = measurementSummarySorting.direction;

                switch (attribute){

                    // Sortowanie według dokładności pomiaru

                    case 'accuracy':

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => b.accuracy - a.accuracy);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].accuracy : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].accuracy : 0;
            
                                return accuracyB - accuracyA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => a.accuracy - b.accuracy);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].accuracy : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].accuracy : 0;
            
                                return accuracyA - accuracyB;
            
                            });
    
                        }

                        break;

                    case 'arrival_time':

                        if(direction === 'descending'){
            
                            current.sort((a, b) => {
            
                                const timeA = a.arrival_time || a.departure_time;

                                const timeB = b.arrival_time || b.departure_time;
                            
                                const dateA = new Date(`1970-01-01 ${timeA}`);

                                const dateB = new Date(`1970-01-01 ${timeB}`);
                            
                                return dateB - dateA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.sort((a, b) => {
            
                                const timeA = a.arrival_time || a.departure_time;

                                const timeB = b.arrival_time || b.departure_time;
                            
                                const dateA = new Date(`1970-01-01 ${timeA}`);

                                const dateB = new Date(`1970-01-01 ${timeB}`);
                            
                                return dateA - dateB;
            
                            });
    
                        }

                        break;

                    case 'departure_time':

                        if(direction === 'descending'){
            
                            current.sort((a, b) => {
            
                                const timeA = a.departure_time || a.arrival_time;

                                const timeB = b.departure_time || b.arrival_time;
                            
                                const dateA = new Date(`1970-01-01 ${timeA}`);

                                const dateB = new Date(`1970-01-01 ${timeB}`);
                            
                                return dateB - dateA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.sort((a, b) => {
            
                                const timeA = a.departure_time || a.arrival_time;

                                const timeB = b.departure_time || b.arrival_time;
                            
                                const dateA = new Date(`1970-01-01 ${timeA}`);

                                const dateB = new Date(`1970-01-01 ${timeB}`);
                            
                                return dateA - dateB;
            
                            });
    
                        }

                        break;

                    case "arrival_difference":

                        current.forEach(item => {
    
                            const measurements = item.measurements;

                            let arrival_time = item.arrival_time;

                            measurements.forEach(measurement => {

                                if(arrival_time && measurement.arrival_time){

                                    let measured_arrival_time = measurement.arrival_time;

                                    if (arrival_time.length === 5) arrival_time += ":00";

                                    if (measured_arrival_time.length === 5) measured_arrival_time += ":00";

                                    const today = new Date().toISOString().slice(0, 10); 

                                    const date1 = new Date(`${today}T${arrival_time}`);

                                    let date2 = new Date(`${today}T${measured_arrival_time}`);

                                    if(arrival_time.startsWith("23") && measured_arrival_time.startsWith("00")){

                                        date2.setDate(date2.getDate() + 1);

                                    }
                                
                                    const differenceInMillis = date2 - date1;
                                
                                    const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));

                                    measurement.difference = differenceInMinutes;

                                } else {

                                    measurement.difference = 0;

                                }

                            });
        
                        });

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => b.difference - a.difference);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].difference : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].difference : 0;
            
                                return accuracyB - accuracyA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => a.difference - b.difference);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].difference : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].difference : 0;
            
                                return accuracyA - accuracyB;
            
                            });
    
                        }

                        break;

                    case "departure_difference":

                        current.forEach(item => {
    
                            const measurements = item.measurements;

                            let departure_time = item.departure_time;

                            measurements.forEach(measurement => {

                                if(departure_time && measurement.departure_time){

                                    let measured_departure_time = measurement.departure_time;

                                    if (departure_time.length === 5) departure_time += ":00";

                                    if (measured_departure_time.length === 5) measured_departure_time += ":00";

                                    const today = new Date().toISOString().slice(0, 10); 

                                    const date1 = new Date(`${today}T${departure_time}`);

                                    let date2 = new Date(`${today}T${measured_departure_time}`);

                                    if(departure_time.startsWith("23") && measured_departure_time.startsWith("00")){

                                        date2.setDate(date2.getDate() + 1);

                                    }
                                
                                    const differenceInMillis = date2 - date1;
                                
                                    const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));

                                    measurement.difference = differenceInMinutes;

                                } else {

                                    measurement.difference = 0;

                                }

                            });
        
                        });

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => b.difference - a.difference);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].difference : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].difference : 0;
            
                                return accuracyB - accuracyA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => a.difference - b.difference);
            
                            });
            
                            current.sort((a, b) => {
            
                                const accuracyA = a.measurements.length > 0 ? a.measurements[0].difference : 0;
            
                                const accuracyB = b.measurements.length > 0 ? b.measurements[0].difference : 0;
            
                                return accuracyA - accuracyB;
            
                            });
    
                        }

                        break;

                    case 'entered_sum':

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => b.entered_sum - a.entered_sum);
            
                            });
            
                            current.sort((a, b) => {
            
                                const exitedA = a.measurements.length > 0 ? a.measurements[0].entered_sum : 0;
            
                                const exitedB = b.measurements.length > 0 ? b.measurements[0].entered_sum : 0;
            
                                return exitedB - exitedA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => a.entered_sum - b.entered_sum);
            
                            });
            
                            current.sort((a, b) => {
            
                                const exitedA = a.measurements.length > 0 ? a.measurements[0].entered_sum : 0;
            
                                const exitedB = b.measurements.length > 0 ? b.measurements[0].entered_sum : 0;
            
                                return exitedA - exitedB;
            
                            });
    
                        }

                        break;

                    case 'exited_sum':

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => b.exited_sum - a.exited_sum);
            
                            });
            
                            current.sort((a, b) => {
            
                                const exitedA = a.measurements.length > 0 ? a.measurements[0].exited_sum : 0;
            
                                const exitedB = b.measurements.length > 0 ? b.measurements[0].exited_sum : 0;
            
                                return exitedB - exitedA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => a.exited_sum - b.exited_sum);
            
                            });
            
                            current.sort((a, b) => {
            
                                const exitedA = a.measurements.length > 0 ? a.measurements[0].exited_sum : 0;
            
                                const exitedB = b.measurements.length > 0 ? b.measurements[0].exited_sum : 0;
            
                                return exitedA - exitedB;
            
                            });
    
                        }

                        break;

                    case 'total_exchange':

                        if(direction === 'descending'){

                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => (b.entered_sum + b.exited_sum) - (a.entered_sum + a.exited_sum));
            
                            });
            
                            current.sort((a, b) => {
            
                                const exchangeA = a.measurements.length > 0 ? (a.measurements[0].entered_sum + a.measurements[0].exited_sum) : 0;
            
                                const exchangeB = b.measurements.length > 0 ? (b.measurements[0].entered_sum + b.measurements[0].exited_sum) : 0;
            
                                return exchangeB - exchangeA;
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.forEach(item => {
    
                                item.measurements.sort((a, b) => (a.entered_sum + a.exited_sum) - (b.entered_sum + b.exited_sum));
            
                            });
            
                            current.sort((a, b) => {
            
                                const exchangeA = a.measurements.length > 0 ? (a.measurements[0].entered_sum + a.measurements[0].exited_sum) : 0;
            
                                const exchangeB = b.measurements.length > 0 ? (b.measurements[0].entered_sum + b.measurements[0].exited_sum) : 0;
            
                                return exchangeA - exchangeB;
            
                            });
    
                        }

                        break;

                    case 'train_number':

                        if(direction === 'descending'){
            
                            current.sort((a, b) => {
            
                                return b.train_number.localeCompare(a.train_number);
            
                            });
    
                        } else if(direction === 'ascending'){
    
                            current.sort((a, b) => {
            
                                return a.train_number.localeCompare(b.train_number);
            
                            });
    
                        }

                        break;

                    default:
                    
                }

                setOutputMeasurementSummaryData(current);

            } else {

                setOutputMeasurementSummaryData(current);

            }

        }

    }, [updatedMeasurementData, measurementSummarySorting]);

    const [selectedMeasuredTrains, setSelectedMeasuredTrains] = useState([]);

    const selectMeasurementRow = (event) => {

        const train_id = event.target.value;

        const current_selected = [...selectedMeasuredTrains];

        if(current_selected.includes(train_id)){

            current_selected.splice(current_selected.indexOf(train_id), 1);

        } else {

            current_selected.push(train_id);

        }

        setSelectedMeasuredTrains(current_selected);

    };

    const isMeasuredTrainSelected = (train_id) => {

        return selectedMeasuredTrains.includes(train_id);

    };

    const [isMeasuredTrainChecked, setIsMeasuredTrainChecked] = useState(true);

    const inverseMeasuredSelection = () => {

        setIsMeasuredTrainChecked(prevChecked => !prevChecked);

        const current_ids = updatedMeasurementData.map(item => item.train_id);

        const current_selected = [...selectedMeasuredTrains];

        current_ids.forEach(value => {

            if(current_selected.includes(value)){

                current_selected.splice(current_selected.indexOf(value), 1);

            } else {

                current_selected.push(value);

            }

        });

        setSelectedMeasuredTrains(current_selected);

    };

    function getAccuracy(accuracy){

        if(accuracy === 5){
            return "99 %";
        }

        if(accuracy === 4){
            return "95 %";
        }

        if(accuracy === 3){
            return "90 %";
        }

        if(accuracy === 2){
            return "80 %";
        }

        if(accuracy === 1){
            return "<80 %";
        }

    }

    function getJobType(job_type){

        if(job_type === 'normal'){
            return "zwykłe";
        }

        if(job_type === 'check'){
            return "kontrolne";
        }

    }

    function calculateTimeDifference(time1, time2){

        if (time1.length === 5) time1 += ":00";

        if (time2.length === 5) time2 += ":00";

        const today = new Date().toISOString().slice(0, 10); 

        const date1 = new Date(`${today}T${time1}`);

        let date2 = new Date(`${today}T${time2}`);

        if(time1.startsWith("23") && time2.startsWith("00")){

            date2.setDate(date2.getDate() + 1);

        }
    
        const differenceInMillis = date2 - date1;
    
        const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));
    
        return `${differenceInMinutes} min.`;

    }

    function cancelDetailedProgressView(){

        setMeasurementSummaryData({date: null, measurements: [], trains: [], station_id: null, station_name: null});

        setAppLayer(400);

    }

    // Sortowanie pociągów

    function sortMeasurementSummaryTable(attribute){

        let current_attribute = measurementSummarySorting.attribute;

        let current_direction = measurementSummarySorting.direction;

        let new_direction;

        if(attribute === current_attribute){

            if(current_direction === 'ascending'){

                new_direction = 'descending';

            } else {

                new_direction = 'ascending';

            }

        } else {

            new_direction = 'descending';

        }

        setMeasurementSummarySorting({attribute: attribute, direction: new_direction});

    }

    // Szczegółowy podgląd zadania - 550

    const jobTableRef = useRef(null);

    const [jobScrollPosition, setJobScrollPosition] = useState(0);

    function showDetailedJob(job){

        let job_number = job.job_number;

        let station_id = job.station_id;

        let train_id = job.train_id;

        let recording_date = job.recording_date;

        getDetailedJobData(job, job_number, recording_date, station_id, train_id);

    }

    function getDetailedJobData(job, job_number, recording_date, station_id, train_id){

        setDetailedJobInfo({
            type: '',
            job_number: '',
            station_id: '',
            station_name: '',
            train_number: '',
            recording_date: ''
        });

        setDetailedJobData([]);

        setJobScrollPosition(jobTableRef.current.scrollTop);

        setAppLayer(550);

        let request_type = 'get detailed job data';

        Axios.post('classes/data.php', { request_type, job_number, recording_date, station_id, train_id }, { timeout: 10000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){

                response.data.job = job;

                if(station_id){

                    response.data.station = station_id;

                    prepareDetailedJobView('station', response.data);

                }

                

                //setAppLayer(450);

                //setMeasurementSummaryData({date: recording_date, measurements: response.data.measurements, trains: unique_array, station_id: station_id, station_name: station_name});

            } else {

                setModal({...modal, show: true, error: true});

                setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }

            setSearching(false);

        }).catch((error) => {

            console.warn(error);

            setModal({...modal, show: true, error: true});

            setModalErrorMessage('Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            setSearching(false);

        });

    }

    const [detailedJobInfo, setDetailedJobInfo] = useState({
        type: '',
        job_number: '',
        station_name: '',
        train_number: '',
        recording_date: ''
    });

    const [detailedJobData, setDetailedJobData] = useState([]);

    const [ftpDirectoriesList, setFTPDirectioriesList] = useState([]);

    const [cameraSlideList, setCameraSlideList] = useState([]);

    function prepareDetailedJobView(type, data){

        setSelectedJobTrains([]);

        setIsJobMeasuredTrainChecked(true);

        const cameras = data.cameras ? data.cameras : [];

        if(cameras.length > 0){

            cameras.forEach(camera => {

                const url = "/photos/" + camera.station_id + "_" + camera.recording_date + "_" + camera.camera_number + ".jpg";

                camera.url = url;

                camera.name = "Kamera " + camera.camera_number;
                
            });

            const sortedList = cameras.sort((a, b) => {

                return Number(a.camera_number) - Number(b.camera_number);

            });

            if(sortedList.length > 3){

                const station_id = sortedList[0].station_id;

                const recording_date = sortedList[0].recording_date;

                const layout_url = "/photos/layout/" + station_id + "_" + recording_date + ".jpg";

                const new_object = {url: layout_url, name: "Schemat montażu"}

                sortedList.unshift(new_object);

            }

            setCameraSlideList(sortedList);

        }

        const all_delays = data.delays ? data.delays : [];

        const ftp = data.ftp ? data.ftp : [];

        setFTPDirectioriesList(ftp);

        const job = data.job

        const all_measurements = data.measurements ? data.measurements : [];

        const stations = data.stations;

        const trains = data.trains;

        const train_numbers = data.train_numbers;

        let recording_date = job.recording_date;

        let job_number = job.job_number;

        if(type === 'station'){

            let station_id = data.station;

            const station_search = stations.find(item => item.station_id === station_id);

            let station_name;

            if(station_search){

                station_name = station_search.name;

            }

            trains.forEach(train => {

                const found = Object.keys(train).find(key => train[key] === station_id);
    
                const index = found.substring(found.indexOf("_") + 1);
    
                train.station_index = index;

                train.arrival_time = train['arrival_hour_'+index];

                train.departure_time = train['departure_hour_'+index];

                train.platform = train['platform_number_'+index];

                const measurements = all_measurements.filter(item => item.train_id === train.train_id);

                measurements.forEach(measurement => {

                    const user_data = users.find(user => user.personal_id === measurement.personal_id);

                    if(user_data){

                        let user_name = user_data.first_name + " " + user_data.surname;

                        measurement.full_name = user_name;

                    }

                });

                train.measurements = measurements;

                const delays = all_delays.filter(item => item.train_id === train.train_id && item.day === recording_date);

                const train_data = train_numbers.find(item => item.train_id === train.train_id);

                if(train_data){

                    train.train_number = train_data.train_number;

                    let first_station_id = train_data.first_station;

                    let last_station_id = train_data.last_station;

                    const first_station_search = stations.find(item => item.station_id === first_station_id);

                    const last_station_search = stations.find(item => item.station_id === last_station_id);

                    let first_station_name;
                    
                    let last_station_name;

                    if(first_station_search){

                        first_station_name = first_station_search.name;

                    }

                    if(last_station_search){

                        last_station_name = last_station_search.name;

                    }

                    train.relation = first_station_name + " - " + last_station_name;

                }

                if(delays.length > 0){

                    const exact = delays.filter(item => item.station_id === station_id);

                    if(exact.length > 0){

                        train.delay = 'opóźniony ' + exact[0].delay + ' min.';

                    } else {

                        for(let i=index-1; i>=1 ;i--){

                            let current_index = 'station_'+i;

                            let current_station = train[current_index];

                            const found = delays.filter(item => item.station_id === current_station);

                            if(found.length > 0){

                                let difference = index - i;

                                train.delay = 'opóźniony ' + found[0].delay + ' min. ' + difference + ' st. wcześniej';

                                break;

                            }

                        }

                    }

                } else {

                    train.delay = null;

                }

            });
    
            const sorted = trains.sort((a, b) => {
    
                const stationIndexA = a.station_index;
                const stationIndexB = b.station_index;

                const departureA = a["departure_hour_" + stationIndexA];
                const departureB = b["departure_hour_" + stationIndexB];

                const arrivalA = a["arrival_hour_" + stationIndexA];
                const arrivalB = b["arrival_hour_" + stationIndexB];
    
                const timeA = departureA || arrivalA;
                const timeB = departureB || arrivalB
            
                const dateA = new Date(`1970-01-01 ${timeA}`);
                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });

            setDetailedJobInfo({
                type: 'station',
                job_number: job_number,
                station_id: station_id,
                station_name: station_name,
                train_number: '',
                recording_date: recording_date
            });

            setDetailedJobData(sorted);

        } else {

            // Zadanie pomiaru pociągu

        }

    }

    const [selectedJobTrains, setSelectedJobTrains] = useState([]);

    const selectJobMeasurementRow = (event) => {

        const train_id = event.target.value;

        const current_selected = [...selectedJobTrains];

        if(current_selected.includes(train_id)){

            current_selected.splice(current_selected.indexOf(train_id), 1);

        } else {

            current_selected.push(train_id);

        }

        setSelectedJobTrains(current_selected);

    };

    const isJobMeasuredTrainSelected = (train_id) => {

        return selectedJobTrains.includes(train_id);

    };

    const [isJobMeasuredTrainChecked, setIsJobMeasuredTrainChecked] = useState(true);

    const inverseJobMeasuredSelection = () => {

        setIsJobMeasuredTrainChecked(prevChecked => !prevChecked);

        const current_ids = detailedJobData.map(item => item.train_id);

        const current_selected = [...selectedJobTrains];

        current_ids.forEach(value => {

            if(current_selected.includes(value)){

                current_selected.splice(current_selected.indexOf(value), 1);

            } else {

                current_selected.push(value);

            }

        });

        setSelectedJobTrains(current_selected);

    };

    function cancelDetailedJobView(){

        setAppLayer(500);

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Podgląd zadania jako pracownik

    const [userWithJobsSearching, setUserWithJobsSearching] = useState(true);

    const [usersWithJobs, setUsersWithJobs] = useState([]);

    function getUsersWithJobsData(job){

        let request_type = 'get users with jobs';

        setUserWithJobsSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){
            if(response.data.users_with_jobs){
                const sorted = response.data.users_with_jobs.sort((a, b) => a.surname.localeCompare(b.surname));
                setUsersWithJobs(sorted);
                if(job){
                    let personal_id = job.personal_id;
                    setSelectedWorkerView(personal_id);
                }
            } else {
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
            }
            setUserWithJobsSearching(false);
        }).catch((error) => {
            console.warn(error);
            setUserWithJobsSearching(false);
            setModal({...modal, show: true, info: true});
            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');
        });

    }

    const [selectedWorkerView, setSelectedWorkerView] = useState('');

    function onSelectedWorkerViewChange(e){

        const value = e.target.value;

        setSelectedWorkerView(value);

    }

    // Pobieranie danych dla wybranego pracownika

    const [activeJob, setActiveJob] = useState(null);

    // Aktualnie wybrane zadanie

    const latestActiveJob = useRef();

    useEffect(() => {

        latestActiveJob.current = activeJob;

    }, [activeJob]);

    const [userViewJobsWithTrains, setUserViewJobsWithTrains] = useState([]);

    const [userViewJobs, setUserViewJobs] = useState([]);

    const [userViewDelays, setUserViewDelays] = useState([]);

    const [userViewStations, setUserViewStations] = useState([]);

    const [userViewTrainNumbers, setUserViewTrainNumbers] = useState([]);

    const [userViewCameras, setUserViewCameras] = useState([]);

    const [userViewRecordings, setUserViewRecordings] = useState([]);

    const [userViewFtp, setUserViewFtp] = useState([]);

    const [userViewSearching, setUserViewSearching] = useState(false);

    useEffect(() => {

        if(selectedWorkerView !== ''){

            getWorkerViewData();

            setUpdatedUserViewJobs([]);

            setActiveJob(null);

        }

        function getWorkerViewData(){

            let personal_id = selectedWorkerView;
    
            let request_type = 'get data';

            let admin_override = true;

            setJobFilters({job_station_name: '', job_date: ''});
    
            setUserViewSearching(true);
    
            Axios.post('classes/data.php', { personal_id, request_type, admin_override }, { timeout: 30000 }).then(function(response){
                if(typeof response.data === 'object' || Array.isArray(response.data)){
                    if(Array.isArray(response.data.jobs_trains)){
                        setUserViewJobsWithTrains(response.data.jobs_trains);
                        const jobs_array = response.data.jobs_trains.map(obj => obj['job']);
                        setUserViewJobs(jobs_array);
                    }
                    if(Array.isArray(response.data.delays)){
                        setUserViewDelays(response.data.delays);
                    }
                    if(Array.isArray(response.data.stations)){
                        setUserViewStations(response.data.stations);
                    }
                    if(Array.isArray(response.data.train_numbers)){
                        setUserViewTrainNumbers(response.data.train_numbers);
                    }
                    if(Array.isArray(response.data.cameras)){
                        setUserViewCameras(response.data.cameras);
                    }
                    if(Array.isArray(response.data.recordings)){
                        setUserViewRecordings(response.data.recordings);
                    }
                    if(Array.isArray(response.data.ftp)){
                        setUserViewFtp(response.data.ftp);
                    }
                } else {
                    setModal({...modal, show: true, info: true});
                    setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
                }
                setUserViewSearching(false);
            }).catch((error) => {
                console.warn(error);
                setUserViewSearching(false);
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            });
    
        }

        // eslint-disable-next-line
    }, [selectedWorkerView]);

    // Wyodrębnianie unikatowych stacji i dat zadań

    const [userViewJobDates, setUserViewJobDates] = useState([]);

    const [userViewJobStations, setUserViewJobStations] = useState([]);

    useEffect(() => {

        if(userViewJobs.length > 0){ 

            setUserViewJobDates(userViewJobs.reduce((unique, obj) => {
                const formattedDate = formatDate(obj.recording_date);
                if (!unique.includes(formattedDate)) {
                  unique.push(formattedDate);
                }
                return unique;
            }, []).sort((a, b) => {
                const [dayA, monthA, yearA] = a.split("/");
                const [dayB, monthB, yearB] = b.split("/");
                const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
                const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
                return dateA - dateB;
            }));

        }
        
    }, [userViewJobs]);

    // Uzupełnianie zadań o nazwy stacji i numery pociągów

    const [updatedUserViewJobs, setUpdatedUserViewJobs] = useState([]);

    useEffect(() => {

        if(userViewJobs.length > 0 && userViewStations.length > 0 && userViewTrainNumbers.length > 0){ 

            const jobsWithStationName = userViewJobs.filter(job => job.station_name);

            const uniqueStationNames = [...new Set(jobsWithStationName.map(job => job.station_name))];

            const sortedUniqueStationNames = uniqueStationNames.sort((a, b) => a.localeCompare(b));

            setUserViewJobStations(sortedUniqueStationNames);

            const updated = [...userViewJobs];

            updated.forEach(job => {

                if(job.train_id){

                    const train_search = userViewTrainNumbers.find(u => u.train_id === job.train_id);

                    if(train_search){

                        const first_station = userViewStations.find(u => u.station_id === train_search.first_station);

                        const last_station = userViewStations.find(u => u.station_id === train_search.last_station);

                        job.train_number = train_search.train_number;

                        if(first_station){

                            job.first_station_name = first_station.name;

                        }

                        if(last_station){

                            job.last_station_name = last_station.name;

                        }

                    }

                }

            });

            setUpdatedUserViewJobs(updated)

        }
        
    }, [userViewJobs, userViewStations, userViewTrainNumbers]);

    // Uzupełnianie pociągów o nazwy stacji i numer PKP

    const [userViewTrains, setUserViewTrains] = useState([]);

    const [updatedUserViewTrains, setUpdatedUserViewTrains] = useState([]);

    useEffect(() => {

        if(userViewTrains.length > 0 && userViewStations.length > 0 && userViewTrainNumbers.length > 0){ 

            const updated = [...userViewTrains];

            updated.forEach(train => {

                const train_search = userViewTrainNumbers.find(u => u.train_id === train.train_id);

                if(train_search){

                    train.train_number = train_search.train_number;

                    const first_station = userViewStations.find(u => u.station_id === train_search.first_station);

                    const last_station = userViewStations.find(u => u.station_id === train_search.last_station);

                    if(first_station){

                        train.first_station_name = first_station.name;

                    }

                    if(last_station){

                        train.last_station_name = last_station.name;

                    }

                }

            });

            setUpdatedUserViewTrains(updated);       

        }
        
    }, [userViewTrains, userViewStations, userViewTrainNumbers]);

    // Pobieranie pomiarów

    const [mergedUserViewTrains, setMergedUserViewTrains] = useState([]);

    useEffect(() => {

        if(updatedUserViewTrains.length > 0 && latestActiveJob.current){ 

            if(latestActiveJob.current.station_id){

                const train_ids = updatedUserViewTrains.map(train => train.train_id);

                getCompletedMeasurements(train_ids);

            }

            if(updatedUserViewTrains[0].stations){

                const stations_ids = updatedUserViewTrains[0].stations.map(array => array[0]);

                getCompletedMeasurements(stations_ids);

            }

        }

        function getCompletedMeasurements(data){

            let job_number = latestActiveJob.current.job_number;

            let personal_id = latestActiveJob.current.personal_id;

            let request_type = 'get data';

            let station_id = latestActiveJob.current.station_id;

            let train_id = latestActiveJob.current.train_id;

            Axios.post('classes/measurements.php', { personal_id, job_number, data, station_id, train_id, request_type }, { timeout: 10000 }).then(function(response){
                if(Array.isArray(response.data.measurements) && Array.isArray(response.data.photos)){
                    mergeTrainData(response.data);
                }
            }).catch((error) => {
                console.warn(error);
            });

        }

        function mergeTrainData(data){

            const db_measurements = data.measurements;
    
            const db_photos = data.photos;
    
            let groupedObjects;
    
            let newArrayWithMeasurements;
    
            if(latestActiveJob.current.station_id){
    
                groupedObjects = db_measurements.reduce((acc, obj) => {
                    const { id, train_id } = obj;
                    if (!acc[train_id] || acc[train_id].id < id) {
                        acc[train_id] = obj;
                    }
                    return acc;
                }, {});
    
                const uniqueObjects = Object.values(groupedObjects);
    
                newArrayWithMeasurements = updatedUserViewTrains.map(obj => {
    
                    const { train_id } = obj;
        
                    const correspondingObject = uniqueObjects.find(uniqueObj => uniqueObj.train_id === train_id);
        
                    obj.measurement = correspondingObject || null;
        
                    return obj;
        
                });
    
            } else {
    
                groupedObjects = db_measurements.reduce((acc, obj) => {
                    const { id, station_id } = obj;
                    if (!acc[station_id] || acc[station_id].id < id) {
                        acc[station_id] = obj;
                    }
                    return acc;
                }, {});
    
                const uniqueObjects = Object.values(groupedObjects);
    
                newArrayWithMeasurements = updatedUserViewTrains;
    
                newArrayWithMeasurements[0].measurement = uniqueObjects;
    
            }
                
            const updated = newArrayWithMeasurements.map(train => {
    
                const trainPhotos = db_photos.filter(photo => photo.train_id === train.train_id);
    
                return { ...train, photos: trainPhotos };
    
            });
    
            setMergedUserViewTrains(updated);
            
        };

    }, [updatedUserViewTrains]);

    // Filtrowanie dostępnych zadań

    const [filteredUserViewJobs, setFilteredUserViewJobs] = useState([]);

    const [jobFilters, setJobFilters] = useState({job_station_name: '', job_date: ''});

    // Usuwanie nieaktywnych stacji/dat przy filtrowaniu

    useEffect(() => {

        if(filteredUserViewJobs.length > 0){

            setUserViewJobDates(filteredUserViewJobs.reduce((unique, obj) => {
                const formattedDate = formatDate(obj.recording_date);
                if (!unique.includes(formattedDate)) {
                  unique.push(formattedDate);
                }
                return unique;
            }, []).sort((a, b) => {
                const [dayA, monthA, yearA] = a.split("/");
                const [dayB, monthB, yearB] = b.split("/");
                const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
                const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
                return dateA - dateB;
            }));

            const jobsWithStationName = filteredUserViewJobs.filter(job => job.station_name);

            const uniqueStationNames = [...new Set(jobsWithStationName.map(job => job.station_name))];

            const sortedUniqueStationNames = uniqueStationNames.sort((a, b) => a.localeCompare(b));

            setUserViewJobStations(sortedUniqueStationNames);

        } else {

            setUserViewJobDates([]);

            setUserViewJobStations([]);

        }
        
    }, [filteredUserViewJobs]);

    useEffect(() => {

        if(updatedUserViewJobs.length > 0){

            const filteredData = updatedUserViewJobs.filter(item => {
                if(jobFilters.job_station_name === '' && jobFilters.job_date === '') {
                    return true;
                }
                return (
                    (jobFilters.job_station_name === '' || item.station_name === jobFilters.job_station_name) &&
                    (jobFilters.job_date === '' || formatDate(item.recording_date) === jobFilters.job_date)
                );
            });

            filteredData.sort((a, b) => a.job_number - b.job_number);
    
            setFilteredUserViewJobs(filteredData);

        }
        
    }, [updatedUserViewJobs, jobFilters]);

    function onUserViewJobFilterChange(e) {

        const value = e.target.value;

        const name = e.target.name;

        setJobFilters(prevFilters => {
            return {
                ...prevFilters,
                [name]: value
            }
        });

    }

    // FTP

    const [ftpList, setFtpList] = useState([]);

    function copyText(id){

        let ele = document.getElementById(id);
        let text = ele.innerText;
        let tempTextArea = document.createElement('textarea');
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);   
        tempTextArea.select();
        document.execCommand('copy'); 
        document.body.removeChild(tempTextArea);

        if(id === 'ftp_host'){

            setButtonStatus({ftp_host: true, ftp_user: false, ftp_password: false});

        } else if(id === 'ftp_user'){

            setButtonStatus({ftp_host: false, ftp_user: true, ftp_password: false});

        } else {

            setButtonStatus({ftp_host: false, ftp_user: false, ftp_password: true});

        }

        setTimeout(() => { setButtonStatus({ftp_host: false, ftp_user: false, ftp_password: false}) }, 2000);

    }

    const [buttonStatus, setButtonStatus] = useState({
        ftp_host: false,
        ftp_user: false,
        ftp_password: false
    });

    // Aktywowanie zadania

    useEffect(() => {

        if(userViewJobsWithTrains.length > 0 && jobToOpen){

            let job_number = jobToOpen.job_number;

            activateJob(job_number);

            setAppLayer(610);

            setModalSpin(false);

            setModalMessage('');

            closeModal();

            setJobToOpen(null);

        }

        // eslint-disable-next-line
    }, [userViewJobsWithTrains]);

    function activateJob(job_number){

        const match = userViewJobsWithTrains.find(obj => obj.job.job_number === job_number);

        const station_id = match.job.station_id;

        const rec_date = match.job.recording_date;

        if(station_id){

            match.trains.forEach(train => {

                const found = Object.keys(train).find(key => train[key] === station_id);
    
                const index = found.substring(found.indexOf("_") + 1);
    
                train.station_index = index;

                const delay = userViewDelays.filter(item => item.train_id === train.train_id && item.day === rec_date);

                if(delay.length > 0){

                    const exact = delay.filter(item => item.station_id === station_id);

                    if(exact.length > 0){

                        train.delay = 'opóźniony ' + exact[0].delay + ' min.';

                    } else {

                        for(let i=index-1; i>=1 ;i--){

                            let current_index = 'station_'+i;

                            let current_station = train[current_index];

                            const found = delay.filter(item => item.station_id === current_station);

                            if(found.length > 0){

                                let difference = index - i;

                                train.delay = 'opóźniony ' + found[0].delay + ' min. ' + difference + ' st. wcześniej';

                                break;

                            }

                        }

                    }

                } else {

                    train.delay = null;

                }

            });
    
            const sortedTrains = match.trains.sort((a, b) => {
    
                const stationIndexA = a.station_index;
                const stationIndexB = b.station_index;

                const departureA = a["departure_hour_" + stationIndexA];
                const departureB = b["departure_hour_" + stationIndexB];

                const arrivalA = a["arrival_hour_" + stationIndexA];
                const arrivalB = b["arrival_hour_" + stationIndexB];
    
                const timeA = departureA || arrivalA;

                const timeB = departureB || arrivalB
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });
    
            setUserViewTrains(sortedTrains);

            const cameras_list = userViewCameras.filter(item => item.station_id === station_id && item.recording_date === rec_date);

            setCamerasList(cameras_list);

            const ftp_list = userViewFtp.filter(item => item.station_id === station_id && item.recording_date === rec_date);

            setFtpList(ftp_list);

        } else {

            const train = match.trains[0];

            const arr = [];

            for (let i = 1; i <= 60; i++) {

                let current_station = train['station_'+i];

                if(!current_station){

                    break;

                } else {

                    const small = [];

                    window['station_' + i + '_name'] = stations.find(u => u.station_id === current_station);

                    train['station_' + i + '_name'] = window['station_' + i + '_name'].name;

                    small.push(current_station, window['station_' + i + '_name'].name, train['arrival_hour_'+i], train['departure_hour_'+i], train['platform_number_'+i], train['lane_number_'+i]);

                    const delay = userViewDelays.filter(item => item.station_id === current_station && item.train_id === train.train_id && item.day === rec_date);

                    if(delay.length > 0){

                        let latest = delay.length -1;

                        small.push(delay[latest].delay);

                    } else {

                        small.push(null);

                    }

                    if(userViewRecordings.find(u => u.station_id === current_station && u.recording_date === rec_date)){

                        small.push(true);

                    } else {

                        small.push(false);

                    }

                    arr.push(small);

                }

            }

            train.stations = arr;

            const updatedTrain = [];

            updatedTrain.push(train);

            setUserViewTrains(updatedTrain);

        }

        setActiveJob(match.job);

    }

    // Wyznaczanie slajdów do podglądu obrazów z kamer

    const [camerasList, setCamerasList] = useState([]);

    const [cameraSlides, setCameraSlides] = useState([]);

    useEffect(() => {

        if(camerasList.length > 0){

            const current_list = [...camerasList];

            current_list.forEach(camera => {

                const url = "/photos/" + camera.station_id + "_" + camera.recording_date + "_" + camera.camera_number + ".jpg";

                camera.url = url;

                camera.name = "Kamera " + camera.camera_number;
                
            });

            const sortedList = current_list.sort((a, b) => {

                return Number(a.camera_number) - Number(b.camera_number);

            });

            if(sortedList.length > 3){

                const station_id = sortedList[0].station_id;

                const recording_date = sortedList[0].recording_date;

                const layout_url = "/photos/layout/" + station_id + "_" + recording_date + ".jpg";

                const new_object = {url: layout_url, name: "Schemat montażu"}

                sortedList.unshift(new_object);

            }

            setCameraSlides(sortedList);

        } else {

            setCameraSlides([]);

        }

    }, [camerasList]);

    const [openModal, setOpenModal] = useState(false);

    const [imageIndex, setImageIndex] = useState();

    const [modalSlides, setModalSlides] = useState([]);

    function runModal(id){
        setImageIndex(id);
        setOpenModal(true);
    }

    useEffect(() => {
        setModalSlides(
            cameraSlides.map((image) => {
                return { src: image.url };
            })
        );
    }, [cameraSlides]);

    function handleMarkerGallery(index){
        runModal(index);
    }

    // Zmiana zadania

    function exitJob(){
        setActiveJob(null);
        setMergedUserViewTrains([]);
        setActiveTrain({});
        setActiveStation(null);
        clearJobFormData();
        setCamerasList([]);
        setFtpList([]);
        setUserViewTrains([]);
    }

    // Aktywowanie pociągu lub stacji do pomiarów

    const [activeTrain, setActiveTrain] = useState({});

    const latestActiveTrain = useRef();

    useEffect(() => {

        latestActiveTrain.current = activeTrain;
        
    }, [activeTrain]);

    function activateTrain(id){

        const train_to_activate = mergedUserViewTrains.filter(obj => {return obj.id === id});

        const train = train_to_activate[0];

        activateSelectedTrain(train);

    }

    function activateSelectedTrain(train){

        setMeasurementFormData({});

        setJobTime({arrival: "", departure: ""});

        setAccuracy("");

        setAdditionalComment("");

        setActiveTrain(train);

        if(train.measurement){

            restoreMeasurements(train.measurement);

        }

    }

    const [activeStation, setActiveStation] = useState(null);

    const latestActiveStation = useRef();

    useEffect(() => {

        latestActiveStation.current = activeStation;
        
    }, [activeStation]);

    function activateSelectedStation(station_id){

        setMeasurementFormData({});
        setJobTime({arrival: "", departure: ""});
        setAccuracy("");
        setAdditionalComment("");

        setActiveStation(station_id);

        let recording_date = activeJob.recording_date;

        const cameras_list = userViewCameras.filter(item => item.station_id === station_id && item.recording_date === recording_date);

        setCamerasList(cameras_list);

        const ftp_list = userViewFtp.filter(item => item.station_id === station_id && item.recording_date === recording_date);

        setFtpList(ftp_list);

        if(mergedUserViewTrains[0].measurement){

            const old_measurement = mergedUserViewTrains[0].measurement.filter(obj => {return obj.station_id === station_id});

            if(old_measurement.length > 0){

                restoreMeasurements(old_measurement);

            } 

        }

    }

    // Przywracanie ostatniego pomiaru

    function restoreMeasurements(data){

        if(Array.isArray(data)){
            data = data[0];
        }

        const oldMeasurementFormData = {};

        if(data.entered_1 !== null && data.entered_1 !== undefined) oldMeasurementFormData.entered_1 = data.entered_1;

        if(data.entered_2 !== null && data.entered_2 !== undefined) oldMeasurementFormData.entered_2 = data.entered_2;

        if(data.entered_3 !== null && data.entered_3 !== undefined) oldMeasurementFormData.entered_3 = data.entered_3;

        if(data.entered_4 !== null && data.entered_4 !== undefined) oldMeasurementFormData.entered_4 = data.entered_4;

        if(data.entered_5 !== null && data.entered_5 !== undefined) oldMeasurementFormData.entered_5 = data.entered_5;

        if(data.entered_6 !== null && data.entered_6 !== undefined) oldMeasurementFormData.entered_6 = data.entered_6;

        if(data.exited_1 !== null && data.exited_1 !== undefined) oldMeasurementFormData.exited_1 = data.exited_1;

        if(data.exited_2 !== null && data.exited_2 !== undefined) oldMeasurementFormData.exited_2 = data.exited_2;

        if(data.exited_3 !== null && data.exited_3 !== undefined) oldMeasurementFormData.exited_3 = data.exited_3;

        if(data.exited_4 !== null && data.exited_4 !== undefined) oldMeasurementFormData.exited_4 = data.exited_4;

        if(data.exited_5 !== null && data.exited_5 !== undefined) oldMeasurementFormData.exited_5 = data.exited_5;

        if(data.exited_6 !== null && data.exited_6 !== undefined) oldMeasurementFormData.exited_6 = data.exited_6;

        setMeasurementFormData(oldMeasurementFormData);

        const old_time = {};

        old_time.arrival = data.arrival_time ? data.arrival_time : "";

        old_time.departure = data.departure_time ? data.departure_time : "";

        setJobTime(old_time);

        if(data.accuracy) setAccuracy(data.accuracy);

        if(data.comments) setAdditionalComment(data.comments);

    }

    const [measurementFormData, setMeasurementFormData] = useState({});

    const [measurementFormSummary, setMeasurementFormSummary] = useState({entered_sum: "", exited_sum: ""});

    useEffect(() => {

        if(Object.keys(measurementFormData).length > 0){

            const entered_sum = calculateEnteredSum();

            const exited_sum = calculateExitedSum();
    
            function calculateEnteredSum(){
    
                let entered_1 = measurementFormData.entered_1 ? measurementFormData.entered_1 : 0;
                let entered_2 = measurementFormData.entered_2 ? measurementFormData.entered_2 : 0;
                let entered_3 = measurementFormData.entered_3 ? measurementFormData.entered_3 : 0;
                let entered_4 = measurementFormData.entered_4 ? measurementFormData.entered_4 : 0;
                let entered_5 = measurementFormData.entered_5 ? measurementFormData.entered_5 : 0;
                let entered_6 = measurementFormData.entered_6 ? measurementFormData.entered_6 : 0;
    
                let sum = entered_1 + entered_2 + entered_3 + entered_4 + entered_5 + entered_6;

                if(measurementFormData.entered_1 === undefined && measurementFormData.entered_2 === undefined && measurementFormData.entered_3 === undefined && measurementFormData.entered_4 === undefined && measurementFormData.entered_5 === undefined && measurementFormData.entered_6 === undefined){
                    return "";
                } else {
                    return sum;
                }
    
            }

            function calculateExitedSum(){
    
                let exited_1 = measurementFormData.exited_1 ? measurementFormData.exited_1 : 0;
                let exited_2 = measurementFormData.exited_2 ? measurementFormData.exited_2 : 0;
                let exited_3 = measurementFormData.exited_3 ? measurementFormData.exited_3 : 0;
                let exited_4 = measurementFormData.exited_4 ? measurementFormData.exited_4 : 0;
                let exited_5 = measurementFormData.exited_5 ? measurementFormData.exited_5 : 0;
                let exited_6 = measurementFormData.exited_6 ? measurementFormData.exited_6 : 0;
    
                let sum = exited_1 + exited_2 + exited_3 + exited_4 + exited_5 + exited_6;

                //if(!measurementFormData.exited_1 && !measurementFormData.exited_2 && !measurementFormData.exited_3 && !measurementFormData.exited_4 && !measurementFormData.exited_5 && !measurementFormData.exited_6){
                if(measurementFormData.exited_1 === undefined && measurementFormData.exited_2 === undefined && measurementFormData.exited_3 === undefined && measurementFormData.exited_4 === undefined && measurementFormData.exited_5 === undefined && measurementFormData.exited_6 === undefined){
                    return "";
                } else {
                    return sum;
                }
    
            }
    
            setMeasurementFormSummary({entered_sum: entered_sum, exited_sum: exited_sum});

        } else {

            setMeasurementFormSummary({entered_sum: "", exited_sum: ""});

        }

    }, [measurementFormData]);

    // Formularz pomiarowy

    const [jobTime, setJobTime] = useState({arrival: "", departure: ""});

    const [accuracy, setAccuracy] = useState('');

    const [sliderInfo, setSliderInfo] = useState(false);

    const [additionalComment, setAdditionalComment] = useState('');

    function clearJobFormData(){

        setMeasurementFormData({});

        setJobTime({arrival: "", departure: ""});

        setAccuracy('');

        setAdditionalComment('');

    }

    // Podgląd zdjęć

    const [photosToShow, setPhotosToShow] = useState([]);

    function showPhotos(train_id){

        const train = mergedUserViewTrains.find(u => u.train_id === train_id);

        const photos = train.photos;

        photos.forEach(photo => {

            let train_id = photo.train_id;

            let station_id = photo.station_id;

            photo.url = '/photos/trains/' + photo.filename;

            const train_match = trainNumbers.find(u => u.train_id === train_id);

            const station_match = stations.find(u => u.station_id === station_id);

            if(train_match && station_match){

                photo.name = 'Pociąg ' + train_match.train_number + ' na stacji ' + station_match.name;

            }

        });

        setPhotosToShow(photos);

        setModal({...modal, show: true, show_photo: true});

    }

    const [viewModalSlides, setViewModalSlides] = useState([]);

    useEffect(() => {
        setViewModalSlides(
            photosToShow.map((image) => {
                return { src: image.url };
            })
        );
    }, [photosToShow]);

    // Wyznaczanie klasy wiersza w tabeli

    const getClassNames = (train_id, measurement) => {

        if(train_id === activeTrain.train_id){

          return 'currently-measured-train';

        } else if(measurement !== null){

          return 'previously-measured-train';

        } else {

          return '';

        }

    }

    const getStationClassNames = (station_id) => {

        const old_measurement = mergedUserViewTrains[0].measurement.filter(obj => {return obj.station_id === station_id});

        if(station_id === activeStation){

            return 'currently-measured-train';

        } else if(old_measurement.length > 0){

            return 'previously-measured-train';

        } else {

            const all_stations = mergedUserViewTrains[0].stations;

            const current_station = all_stations.filter(innerArray => innerArray[0] === station_id);

            let is_measureable = current_station[0][7];

            if(is_measureable){

                return '';

            } else {

                return 'not-measured-train';

            }

        }

    }

    function getStationName(){

        const found_station = stations.find(u => u.station_id === activeStation);

        return found_station.name;

    }

    function formatTrainNumber(train_number){

        if(!train_number.includes("-")){

            return train_number;

        } else {

            let split = train_number.split("-");

            return <><span className="train-number-split-main">{split[0]}</span><span className="train-number-split-sub">-</span><span className="train-number-split-main">{split[1]}</span></>;

        }

    }

    // Otwieranie zadania - bezpośrednio z widoku realizacji

    const [jobToOpen, setJobToOpen] = useState(null);

    function openJob(job){

        setJobToOpen(job);

        getUsersWithJobsData(job);

        setSelectedWorkerView('');

        setModalMessage('Czekaj, trwa pobieranie danych.');

        setModalSpin(true);

        setModal({...modal, show: true, info: true});

    }

    // Podgląd pociągu

    const [trainViewSearching, setTrainViewSearching] = useState(false);

    const [trainList, setTrainList] = useState([]);

    const [trainNumbersList, setTrainNumbersList] = useState([]);

    const [stationsList, setStationsList] = useState([]);

    const [recordingsList, setRecordingsList] = useState([]);

    const [jobsList, setJobsList] = useState([]);

    const [usersList, setUsersList] = useState([]);

    function getTrainViewData(){

        let request_type = 'get train list data';

        setTrainViewSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){

            if(response.data.success){

                setTrainList(response.data.trains);

                setTrainNumbersList(response.data.train_numbers);

                setStationsList(response.data.stations);

                setRecordingsList(response.data.recordings);

                setJobsList(response.data.jobs);

                setUsersList(response.data.users);

            } else {

                setModal({...modal, show: true, info: true});

                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

            }
            setTrainViewSearching(false);

        }).catch((error) => {

            console.warn(error);

            setTrainViewSearching(false);

            setModal({...modal, show: true, info: true});

            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

        });

    }

    const [trainListWithNumbers, setTrainListWithNumbers] = useState([]);

    useEffect(() => {

        if(trainList.length > 0 && trainNumbersList.length > 0){

            const list = [...trainList];

            list.forEach(train => {

                const train_number = trainNumbersList.filter(item => item.train_id === train.train_id);

                if(train_number.length > 0){

                    if(train.train_id.startsWith('PW')){

                        train.train_number = train_number[0].train_number + " - wakacje";

                    } else {

                        train.train_number = train_number[0].train_number;

                    }

                }

            });

            setTrainListWithNumbers(list);

        }

    }, [trainList, trainNumbersList]);

    const [trainListSelectionData, setTrainListSelectionData] = useState([]);

    useEffect(() => {

        if(trainListWithNumbers.length > 0){

            const list = [...trainListWithNumbers];

            const output = [
                {name: 'Arriva', data: []}, 
                {name: 'Intercity', data: []}, 
                {name: 'Osobowe - R 1****', data: []}, 
                {name: 'Osobowe - R 2****', data: []}, 
                {name: 'Osobowe - R 3****', data: []}, 
                {name: 'Osobowe - R 4****', data: []}, 
                {name: 'Osobowe - R 5****', data: []}, 
                {name: 'Osobowe - R 6****', data: []}, 
                {name: 'Osobowe - R 7****', data: []}, 
                {name: 'Osobowe - R 8****', data: []}, 
                {name: 'Osobowe - R 9****', data: []}, 
                {name: 'Twoje Linie Kolejowe', data: []}, 
                {name: 'Zastepcze', data: []}
            ];

            list.forEach(train => {

                let train_number = train.train_number;

                if (train_number.startsWith('AR')) {

                    output[0].data.push(train_number);

                } else if (train_number.startsWith('IC')) {

                    output[1].data.push(train_number);

                } else if (train_number.startsWith('R')) {

                    let type = train_number[2];

                    switch (type){
    
                        case '1':

                            output[2].data.push(train_number);

                            break;

                        case '2':

                            output[3].data.push(train_number);

                            break;

                        case '3':

                            output[4].data.push(train_number);

                            break;

                        case '4':

                            output[5].data.push(train_number);

                            break;

                        case '5':

                            output[6].data.push(train_number);

                            break;

                        case '6':

                            output[7].data.push(train_number);

                            break;

                        case '7':

                            output[8].data.push(train_number);

                            break;

                        case '8':

                            output[9].data.push(train_number);

                            break;

                        case '9':

                            output[10].data.push(train_number);

                            break;

                        default:

                            //

                    }

                } else if (train_number.startsWith('TLK')) {

                    output[11].data.push(train_number);

                } else if (train_number.startsWith('BUS')) {

                    output[12].data.push(train_number);

                }



            });

            const not_empty = output.filter(item => item.data.length > 0);

            not_empty.forEach(item => {

                item.data.sort((a, b) => {
            
                    return a.localeCompare(b);

                });

            })

            setTrainListSelectionData(not_empty);

        }

    }, [trainListWithNumbers]);

    const [selectedTrainListView, setSelectedTrainListView] = useState({trains_group: '', train: ''});

    function onSelectedTrainViewChange(e){

        const {name, value} = e.target;

        if(name === 'trains_group'){

            setSelectedTrainListView({trains_group: value, train: ''});

        } else {

            setSelectedTrainListView(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            });

        }
        
    }

    const [trainViewDetailedSearching, setTrainViewDetailedSearching] = useState({active: false, search: false});

    useEffect(() => {

        if(selectedTrainListView.train !== ''){

            setOutputTrainList([]);

            getTrainListDetailedData();

        }
        
        // eslint-disable-next-line
    }, [selectedTrainListView.train]);

    useEffect(() => {

        setOutputTrainList([]);

    }, [selectedTrainListView.trains_group]);

    const [outputTrainList, setOutputTrainList] = useState([]);

    function getTrainListDetailedData(){

        let train_number = selectedTrainListView.train;

        const train_data = trainListWithNumbers.filter(item => item.train_number === train_number);

        const train = train_data[0];

        let train_id = train.train_id;

        let request_type = 'get train list detailed data';

        setTrainViewDetailedSearching({active: true, search: true});

        Axios.post('classes/data.php', { request_type, train_id }, { timeout: 10000 }).then(function(response){

            if(typeof response.data === 'object' || Array.isArray(response.data)){

                const data_measurements = response.data.measurements;

                const details = [];

                for(let i = 1; i <= 60; i++){

                    const obj = {};

                    let station_id = train['station_'+i];

                    let arrival_hour = train['arrival_hour_'+i];

                    let departure_hour = train['departure_hour_'+i];

                    let platform_number = train['platform_number_'+i];

                    let lane_number = train['lane_number_'+i];

                    if(!station_id){

                        break;

                    }

                    // Wyznaczanie szczegółów dotyczących stacji

                    obj.station_id = station_id;

                    obj.arrival_hour = arrival_hour;

                    obj.departure_hour = departure_hour;

                    obj.platform_number = platform_number;

                    obj.lane_number = lane_number;

                    const station_match = stationsList.filter(item => item.station_id === station_id);

                    if(station_match.length > 0){

                        const station_name = station_match[0].name;

                        obj.station_name = station_name;

                    }

                    // Sprawdzanie czy stacja jest mierzona i wyznaczanie dat pomiaru

                    const recordings_match = recordingsList.filter(item => item.station_id === station_id);

                    const recording_dates = recordings_match.map(item => item.recording_date);

                    const sorted_recording_dates = recording_dates.sort((a, b) => {

                        return new Date(a) - new Date(b);

                    });

                    obj.recording_dates = sorted_recording_dates;

                    details.push(obj);

                    // Dopasowywanie pomiarów

                    const measurement_match = data_measurements.filter(item => item.station_id === station_id && item.train_id === train_id);

                    measurement_match.forEach(measurement => {

                        // Wyznaczanie użytkownika

                        let personal_id = measurement.personal_id;

                        const user_data = usersList.filter(item => item.personal_id === personal_id);

                        measurement.user_full_name = user_data[0].first_name + " " + user_data[0].surname;

                        // Wyznaczanie rodzaju zadania

                        let job_number = measurement.job_number;

                        const job_data = jobsList.filter(item => item.job_number === job_number);

                        measurement.job_type = job_data[0].type;

                    });

                    const sorted_measurements = measurement_match.sort((a, b) => {

                        return new Date(a.recording_date) - new Date(b.recording_date);

                    });

                    obj.measurements = sorted_measurements;

                }

                train_data[0].output = details;

                setOutputTrainList(train_data);

                setTrainViewDetailedSearching({active: false, search: false});
                
            } else {

                setModal({...modal, show: true, info: true});

                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

                setTrainViewDetailedSearching({active: true, search: false});

            }

        }).catch((error) => {

            console.warn(error);

            setTrainViewDetailedSearching({active: true, search: false});

            setModal({...modal, show: true, info: true});

            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z Pawłem.');

        });

    }

    return (
        <div id="admin-app-outer-container">
            {/* Menu główne */}
            {appLayer !== 100 && 
                <div className="admin-panel-top-menu-button-wrapper">
                    <div id="admin-panel-top-menu-button-inner-wrapper">
                        <button className={appLayer === 200 ? "admin-panel-button admin-panel-button-active" : "admin-panel-button"} onClick={() => setAppLayer(200)}>Użytkownicy</button>
                        <button className={appLayer === 300 || appLayer === 350 ? "admin-panel-button admin-panel-button-active" : "admin-panel-button"} onClick={() => setAppLayer(300)}>Wydawanie</button>
                        <button className={appLayer === 400 || appLayer === 450 ? "admin-panel-button admin-panel-button-active" : "admin-panel-button"} onClick={() => setAppLayer(400)}>Postępy</button>
                        <button className={appLayer === 500 || appLayer === 550 ? "admin-panel-button admin-panel-button-active" : "admin-panel-button"} onClick={() => setAppLayer(500)}>Realizacja</button>
                        <button className={appLayer >= 600 ? "admin-panel-button admin-panel-button-active" : "admin-panel-button"} onClick={() => setAppLayer(600)}>Podgląd</button>
                        <button className="admin-panel-button" onClick={logout}>Wyloguj się<span id="logout-span">&#10140;</span></button>
                    </div>
                </div>
            }
            <div id="admin-app-outer-wrapper">
                {/* Menu górne */}
                {appLayer === 100 && 
                    <div className="admin-panel-main-menu-container">
                        <div className="admin-panel-main-menu-sub-wrapper">
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(200)}>
                                <p className="admin-panel-main-menu-title">Użytkownicy</p>
                                <Icon2/>
                            </div>
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(300)}>
                                <p className="admin-panel-main-menu-title">Wydawanie</p>
                                <Icon6/>
                            </div>
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(400)}>
                                <p className="admin-panel-main-menu-title">Postępy</p>
                                <Icon4/>
                            </div>
                        </div>
                        <div className="admin-panel-main-menu-sub-wrapper">
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(500)}>
                                <p className="admin-panel-main-menu-title">Realizacja</p>
                                <Icon3/>
                            </div>
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(600)}>
                                <p className="admin-panel-main-menu-title">Podgląd</p>
                                <Icon7/>
                            </div>
                            <div className="admin-panel-main-menu-wrapper" onClick={logout}>
                                <p className="admin-panel-main-menu-title">Wyloguj się</p>
                                <Icon5/>
                            </div>
                        </div>
                    </div>
                }
                {/* Panel użytkowników */}
                {appLayer === 200 && 
                    <div className="user-panel-outer-container">
                        <div className="user-panel-function-buttons-wrapper">
                            <button className="admin-function-button" onClick={() => setModal({...modal, show: true, add_user: true})}>Dodaj nowego użytkownika</button>
                            <button className="admin-function-button" onClick={() => getSummaryData("get performance data", null)}>Sprawdź wydajność pracy</button>
                        </div>
                        <div id="user-state-checkbox-wrapper">
                            <span id="user-state-checkbox-description">Pokaż nieaktywnych użytkowników </span>
                            <input onChange={handleShowDeactivatedChange} checked={showDeactivatedUsers} type='checkbox' value={''}></input>
                        </div>
                        <div className="admin-working-middle-outer-wrapper">
                            <div id="admin-table-wrapper-1" className="admin-table-wrapper">
                                <table className="job-working-details-table">
                                    <thead>
                                        <tr>
                                            <th><div className={userTableSorting.attribute === 'surname' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Imię i nazwisko<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('surname')}>{userTableSorting.attribute === 'surname' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Nazwa<br></br>użytkownika</span></th>
                                            <th><div className={userTableSorting.attribute === 'personal_id' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Num. ID<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('personal_id')}>{userTableSorting.attribute === 'personal_id' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Mail</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Telefon</span></th>
                                            {userTable.some(item => item.rating) && <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ocena</span></th>}
                                            {userTable.some(item => item.hour_rate) && <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Stawka</span></th>}
                                            <th><div className={userTableSorting.attribute === 'total_work_time' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Czas pracy<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('total_work_time')}>{userTableSorting.attribute === 'total_work_time' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'total_work_time_in_jobs' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Czas pracy<br></br>w zad.<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('total_work_time_in_jobs')}>{userTableSorting.attribute === 'total_work_time_in_jobs' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'total_jobs' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Wydane<br></br>zadania<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('total_jobs')}>{userTableSorting.attribute === 'total_jobs' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'finished_jobs' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Ukończone<br></br>zadania<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('finished_jobs')}>{userTableSorting.attribute === 'finished_jobs' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'total_stages' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Liczba<br></br>sprawdzonych<br></br>pociągów<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('total_stages')}>{userTableSorting.attribute === 'total_stages' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'average_per_stage' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Średnia<br></br>na pociąg<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('average_per_stage')}>{userTableSorting.attribute === 'average_per_stage' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'average_per_person' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Średnia<br></br>/osobę<br></br>[ s ]<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('average_per_person')}>{userTableSorting.attribute === 'average_per_person' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className={userTableSorting.attribute === 'last_update' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Ostatnio online<br></br><button className="table-sorting-button-2" onClick={() => handleUserTableSorting('last_update')}>{userTableSorting.attribute === 'last_update' ? (userTableSorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Status</span></th>
                                            {userTable.some(item => item.comment) && <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Komentarz</span></th>}
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmień<br></br>hasło</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmień<br></br>stan</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userTable.map((user, index) =>
                                            <tr className={(user.status === 'deactivated' && !showDeactivatedUsers) ? "table-row-hidden" : (user.status === 'deactivated' ? "table-row-deactivated" : "")} key={index}>
                                                <td>{(user.first_name && user.surname) ? user.first_name + " " + user.surname : "-"}</td>
                                                <td>{user.username}</td>
                                                <td>{user.personal_id}</td>
                                                <td>{user.email ? user.email : "-"}</td>
                                                <td>{user.phone_number ? user.phone_number : "-"}</td>
                                                {userTable.some(item => item.rating) && <td>{user.rating ? user.rating : "-"}</td>}
                                                {userTable.some(item => item.hour_rate) && <td>{user.hour_rate ? user.hour_rate : "-"}</td>}
                                                <td>{user.total_work_time_hours ? user.total_work_time_hours : '00:00:00'}</td>
                                                <td>{user.total_work_time_in_jobs ? formatTime(user.total_work_time_in_jobs) : '00:00:00'}</td>
                                                <td>{user.total_jobs}</td>
                                                <td>{user.finished_jobs}</td>
                                                <td>{user.total_stages}</td>
                                                <td>{formatTime(user.average_per_stage)}</td>
                                                <td>{user.average_per_person}</td>
                                                <td>{user.last_update}</td>
                                                <td>{getStatus(user)}</td>
                                                {userTable.some(item => item.comment) && <td>{user.comment ? user.comment : '-'}</td>}
                                                <td><button className="job-working-train-icon" onClick={() => showPasswordChange(user)}><Icon9/></button></td>
                                                <td><button className={user.status === 'active' ? "job-working-train-icon" : "job-working-train-icon job-working-train-icon-red"} onClick={() => showUserDeactivation(user)}><Icon10/></button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                }
                {appLayer === 300 && 
                    <div className="job-assignment-panel-outer-container">
                        {trains.length === 0 && 
                            <div className="waiting-wrapper">
                                {searching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!searching && 
                                    <>
                                        <p className="waiting-message">Brak danych w bazie</p>
                                        <button className='user-top-panel-button' onClick={()=>getData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {trains.length > 0 &&
                            <>
                                {outputTrains.length > 0 &&
                                    <div className="user-panel-function-buttons-wrapper">
                                        <button className={outputTrains.every(obj => selectedTrains.includes(obj.id)) ? "admin-function-button-disabled" : "admin-function-button"} onClick={() => prepareJob('station', null, 'normal')}>Utwórz nowe zadanie</button>
                                    </div>
                                }
                                <div className="top-panel-filters-wrapper">
                                    {uniqueDays.length > 0 && 
                                        <div className="job-selection-filter-wrapper admin-selection-filter-wrapper2">
                                            <p className="admin-selection-filter-label admin-selection-filter-label-responsive">Data pomiarów</p>
                                            <div>
                                                <select
                                                    onChange={onDateChange}
                                                    value={dateFilters.start_date}
                                                    name={'start_date'}
                                                    className={"filter-select"}
                                                >
                                                    {startDates.map((date, index) => (
                                                    <option key={index} value={date}>{formatDate(date)}</option>
                                                    ))}
                                                </select>
                                                <span className="job-selection-filter-date-separator">-</span>
                                                <select
                                                    onChange={(e) => onDateChange(e, 'end')}
                                                    value={dateFilters.end_date}
                                                    name={'end_date'}
                                                    className={"filter-select"}
                                                >
                                                    {endDates.map((date, index) => (
                                                    <option key={index} value={date}>{formatDate(date)}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {uniqueStations.length > 0 && 
                                        <div className="job-selection-filter-wrapper admin-selection-filter-wrapper2">
                                            <p className="admin-selection-filter-label admin-selection-filter-label-responsive">Punkt</p>
                                            <select
                                                onChange={onStationChange}
                                                value={stationFilter}
                                                name={'station_id'}
                                                className={"filter-select"}
                                            >
                                                {filteredStations.sort((a, b) => a.station_name.localeCompare(b.station_name)).map((station, index) => (
                                                <option key={index} value={station.station_id}>{station.station_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                    <div className="job-selection-filter-wrapper admin-selection-filter-wrapper2">
                                        <p className="admin-selection-filter-label admin-selection-filter-label-responsive">Zakres godzinowy</p>
                                        <div>
                                            <input
                                                className="input-number-2 input-time"
                                                type="time"
                                                id="start_hour"
                                                name="start_hour"
                                                value={time.start_hour}
                                                onChange={handleTimeChange}
                                                step="60" 
                                            />
                                            <span className="job-selection-filter-date-separator">-</span>
                                            <input
                                                className="input-number-2 input-time"
                                                type="time"
                                                id="end_hour"
                                                name="end_hour"
                                                value={time.end_hour}
                                                onChange={handleTimeChange}
                                                step="60" 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id="admin-table-wrapper-parent" className="admin-working-middle-outer-wrapper">
                                    <div id="admin-table-wrapper-2" className="admin-table-wrapper" >
                                        <table className="job-working-details-table">
                                            <thead>
                                                <tr>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span job-working-details-column">Wybór<input onChange={inverseSelection} checked={isChecked} type='checkbox' value={''}></input><button title="wczytaj listę pociągów" className="load-button" onClick={() => showImportTrainList()}><img src={LoadIcon} alt='load' className="load"/></button></span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Liczba<br></br>zadań</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Punkt</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pociąg</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Mierzony<br></br>dzień</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wsiadło</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wysiadło</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Liczba<br></br>odczytów</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pomiarowcy</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Analiza<br></br>pociągu</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {outputTrains.length > 0 && outputTrains.map((train, index) =>
                                                    <tr key={index}>
                                                        <th><input onChange={selectRow} checked={!isTrainSelected(train.id)} type='checkbox' value={train.id}></input></th>
                                                        <th>{train.days && train.days.map((day, i) =><span className="recording-date-span" key={i}>{formatDate(day.recording_date)}</span>)}</th>
                                                        <th>{getJobCount(train)}</th>
                                                        <th>{train.station_name}</th>
                                                        <th className="table-no-wrap">{formatTrainNumber(train.train_number)}</th>
                                                        <th>{train.arrival_time ? train.arrival_time : "-"}</th>
                                                        <th>{train.departure_time ? train.departure_time : "-"}</th>
                                                        <th>{train.arrival_time ? train.arrival_time : "-"}</th>
                                                        <th>{train.departure_time ? train.departure_time : "-"}</th>
                                                        <th>{train.platform ? train.platform : "b/d"}</th>
                                                        <th>{(train.measurements && train.measurements.length > 0) ? getMeasurementsResults(train, null) : "-"}</th>
                                                        <th>{(train.measurements && train.measurements.length > 0) ? getMeasurementsResults(train, 'entered') : "-"}</th>
                                                        <th>{(train.measurements && train.measurements.length > 0) ? getMeasurementsResults(train, 'exited') : "-"}</th>
                                                        <th>{(train.measurements && train.measurements.length > 0) ? getMeasurementsCount(train) : '-'}</th>
                                                        <th>{(train.measurements && train.measurements.length > 0) ? getUserNames(train) : '-'}</th>
                                                        <th><button className="job-working-train-icon" onClick={() => prepareJob('train', train.id, 'normal')}><Icon1/></button></th>
                                                    </tr>
                                                )}
                                                {outputTrains.length === 0 &&
                                                    <tr>
                                                        <th colSpan={14}>Żaden pociąg nie spełnia podanych kryteriów</th>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table> 
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                {appLayer === 350 && 
                    <div className="job-assignment-panel-outer-container">
                        {latestUserData.length === 0 && 
                            <div className="waiting-wrapper">
                                {searching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!searching && 
                                    <>
                                        <p className="waiting-message">Brak danych w bazie</p>
                                        <button className='user-top-panel-button' onClick={()=>getLatestUserJobsData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {latestUserData.length > 0 && 
                            <div className="job-working-middle-outer-wrapper">
                                <div className="job-assignment-panel-info-wrapper">
                                    {jobToPrepare.type && 
                                        <div className="job-assignment-panel-info-inner-wrapper">
                                            <p className="job-assignment-panel-text job-assignment-important">Przydziel zadanie wybranemu pracownikowi</p>
                                            <p className="job-assignment-panel-text">Tworzysz zadanie {jobToPrepare.kind === 'normal' ? 'zwykłe' : 'kontrolne'} - {jobToPrepare.type === 'station' ? 'pomiar stacji ' + jobToPrepare.station_name : 'pomiar pociągu '+ jobToPrepare.train_number}</p>
                                            {jobToPrepare.type === 'train' && <p className="job-assignment-panel-text">Relacja: {jobToPrepare.first_station_name + " - " + jobToPrepare.last_station_name}</p>}
                                            {jobToPrepare.kind === 'normal' && jobToPrepare.type === 'station' && !outputTrains.some(obj => selectedTrains.includes(obj.id)) && <p className="job-assignment-panel-text">Zakres godzinowy: {time.start_hour + " - " + time.end_hour}</p>}
                                            {jobToPrepare.date.length > 0 &&
                                                <div className={jobToPrepare.date.length > 1 ? "job-selection-filter-wrapper job-selection-filter-wrapper-important" : "job-selection-filter-wrapper job-selection-filter-wrapper-date"}>
                                                    <p className="admin-selection-filter-label">{jobToPrepare.date.length > 1 ? "Wybrana data nagrania" : "Data nagrania:"}</p>
                                                    <select
                                                        onChange={onDatePrepareChange}
                                                        value={dateToPrepare}
                                                        name={'station_id'}
                                                        className={"filter-select"}
                                                    >
                                                        {jobToPrepare.date.map((date, index) => (
                                                            <option key={index} value={date.recording_date}>{formatDate(date.recording_date)}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div id="admin-table-wrapper-2-5" className="admin-table-wrapper">
                                    <table className="job-working-details-table">
                                        <thead>
                                            <tr>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Imię i nazwisko</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Stawka</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ocena</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przydzielone<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ukończone<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Łączny<br></br>czas pracy</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przydziel<br></br>zadanie</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {updatedUserJobsData.map((user, index) =>
                                                <tr key={index}>
                                                    <th>{user.first_name + " " + user.surname}</th>
                                                    <th>{user.hour_rate ? user.hour_rate : "-"}</th>
                                                    <th>{user.rating ? user.rating : "-"}</th>
                                                    <th>{user.number_of_jobs}</th>
                                                    <th>{user.completed_jobs}</th>
                                                    <th>{user.total_work_time_hours ? user.total_work_time_hours : "00:00:00"}</th>
                                                    <th><button className="job-working-train-icon" onClick={() => prepareCreateJob(user)}><Icon6/></button></th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                </div>
                                <div className="user-panel-function-buttons-wrapper">
                                    <button className="admin-function-button admin-function-button-red" onClick={() => cancelPrepareJob()}>Anuluj i powróć</button>
                                </div>
                            </div>
                        }
                    </div>
                }
                {appLayer === 400 && 
                    <div className="job-assignment-panel-outer-container">
                        {updatedProgressData.length === 0 && 
                            <div className="waiting-wrapper">
                                {searching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!searching && progressStations.length === 0 && 
                                    <>
                                        <p className="waiting-message">Brak danych w bazie</p>
                                        <button className='user-top-panel-button' onClick={()=>getProgressData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {updatedProgressData.length > 0 &&
                            <>
                            {filteredProgressData.length > 0 &&
                                <div className="user-panel-function-buttons-wrapper">
                                    <button className={updatedProgressData.some(obj => obj.check_jobs.length > 0) ? "admin-function-button" : "admin-function-button-disabled"} onClick={() => getSummaryData("get check jobs data", null)}>Pobierz wyniki kontroli</button>
                                </div>
                            }
                            {uniqueProgressDays.length > 0 &&
                                <div className="admin-selection-filter-wrapper2">
                                    <div className="admin-selection-filter-inner-wrapper2">
                                        <p className="admin-selection-filter-label">Data pomiarów:</p>
                                        <div>
                                            <select
                                                onChange={onSelectedProgressDayChange}
                                                value={progressFilters.start_date}
                                                name={'start_date'}
                                                className={"filter-select"}
                                            >
                                                {progressStartDates.map((date, index) => (
                                                    <option key={index} value={date}>{formatDate(date)}</option>
                                                ))}
                                            </select>
                                            <span className="job-selection-filter-date-separator">-</span>
                                            <select
                                                onChange={onSelectedProgressDayChange}
                                                value={progressFilters.end_date}
                                                name={'end_date'}
                                                className={"filter-select"}
                                            >
                                                {progressEndDates.map((date, index) => (
                                                    <option key={index} value={date}>{formatDate(date)}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="admin-selection-filter-inner-wrapper2">
                                        <p className="admin-selection-filter-label">Status:</p>
                                        <div>
                                            <select
                                                onChange={onSelectedProgressDayChange}
                                                value={progressFilters.status}
                                                name={'status'}
                                                className={"filter-select"}
                                            >
                                                <option value={''}>wszystkie</option>
                                                {uniqueProgressStatuses.map((status, index) => (
                                                    <option key={index} value={status}>{status}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="admin-selection-filter-inner-wrapper2">
                                        <p className="admin-selection-filter-label">Wydana kontrola:</p>
                                        <div>
                                            <select
                                                onChange={onSelectedProgressDayChange}
                                                value={progressFilters.check}
                                                name={'check'}
                                                className={"filter-select"}
                                            >
                                                <option value={''}>wszystkie</option>
                                                <option value={'yes'}>tak</option>
                                                <option value={'no'}>nie</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="admin-working-middle-outer-wrapper">
                                <div id="admin-table-wrapper-3" ref={tableRef} className="admin-table-wrapper">
                                    <table className="job-working-details-table">
                                        <thead>
                                            <tr>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Stacja</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Liczba zadań<br></br>zwykłych</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pracownik</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ukończone<br></br>etapy</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Liczba zadań<br></br>kontrolnych</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pracownik</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ukończone<br></br>etapy</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pobierz<br></br>wyniki</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wyświetl<br></br>szczegóły</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredProgressData.length > 0 && filteredProgressData.map((day, index) =>
                                                <tr key={index}>
                                                    <th>{formatDate(day.recording_date)}</th>
                                                    <th>{day.station_name}</th>
                                                    <th>{day.normal_jobs.length}</th>
                                                    <th>{day.normal_jobs.length > 0 ? day.normal_jobs.map((job, i) =>
                                                            <p key={i} className="station-measurements-line station-measurements-line-nowrap">{job.user_full_name}</p>
                                                        ) : "-"}
                                                    </th>
                                                    <th>{day.normal_jobs.length > 0 ? day.normal_jobs.map((job, i) =>
                                                            <p key={i} className="station-measurements-line">{(job.completed_stages ? job.completed_stages : "0") + "/" + job.stages}</p>
                                                        ) : "-"}
                                                    </th>
                                                    <th>{day.check_jobs.length}</th>
                                                    <th>{day.check_jobs.length > 0 ? day.check_jobs.map((job, i) =>
                                                            <p key={i} className="station-measurements-line station-measurements-line-nowrap">{job.user_full_name}</p>
                                                        ) : "-"}
                                                    </th>
                                                    <th>{day.check_jobs.length > 0 ? day.check_jobs.map((job, i) =>
                                                            <p key={i} className="station-measurements-line">{(job.completed_stages ? job.completed_stages : "0") + "/" + job.stages}</p>
                                                        ) : "-"}
                                                    </th>
                                                    <th>{day.normal_jobs.length > 0 ? <button className="job-working-train-icon" onClick={() => getSummaryData("get station detailed data", {station_id: day.station_id, recording_date: day.recording_date})}><Icon11/></button> : "-"}</th>
                                                    <th>{day.normal_jobs.length > 0 ? <button className="job-working-train-icon" onClick={() => showDetailedPoint(day.id)}><Icon3/></button> : "-"}</th>
                                                </tr>
                                            )}
                                            {filteredProgressData.length === 0 &&
                                                <tr>
                                                    <th colSpan={12}>Żadne zadanie nie spełnia podanych kryteriów</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        </>
                        }
                    </div>
                }
                {appLayer === 450 && 
                    <div className="job-assignment-panel-outer-container">
                        {updatedMeasurementData.length === 0 && 
                            <div className="waiting-wrapper">
                                <>
                                    <p className="waiting-message">Generowanie zestawienia</p>
                                    <Gear/>
                                </>
                            </div>
                        }
                        {outputMeasurementSummaryData.length > 0 &&
                            <>
                                <div className="job-assignment-panel-info-inner-wrapper">
                                    <p className="job-assignment-panel-text job-assignment-important">Wyniki pomiarów - stacji {measurementSummaryData.station_name} w dniu {formatDate(measurementSummaryData.date)}<button id="admin-back-button" onClick={() => cancelDetailedProgressView()}>Cofnij<span>&#10140;</span></button></p>
                                </div>
                                <div className="user-panel-function-buttons-wrapper-2">
                                    <button className={outputMeasurementSummaryData.filter(obj => obj.measurements.length > 0).every(obj => selectedMeasuredTrains.includes(obj.train_id)) ? "admin-function-button admin-function-button-disabled" : "admin-function-button"} onClick={() => prepareJob('station', null, 'check')}>Utwórz zadanie kontrolne</button>
                                </div>
                                <div className="admin-working-middle-outer-wrapper">
                                    <div id="admin-table-wrapper-3-5" className="admin-table-wrapper">
                                        <table className="job-working-details-table">
                                            <thead>
                                                <tr>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span job-working-details-column">Wybór{updatedMeasurementData.some(obj => obj.measurements.length > 0) && <input onChange={inverseMeasuredSelection} checked={isMeasuredTrainChecked} type='checkbox' value={''}></input>}</span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'train_number' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Numer<br></br>pociągu<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('train_number')}>{measurementSummarySorting.attribute === 'train_number' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Relacja</span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'arrival_time' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Godzina<br></br>przyjazdu<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('arrival_time')}>{measurementSummarySorting.attribute === 'arrival_time' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'departure_time' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Godzina<br></br>odjazdu<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('departure_time')}>{measurementSummarySorting.attribute === 'departure_time' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pracownik</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Numer<br></br>zadania</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Typ<br></br>zadania</span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'entered_sum' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Wsiadło<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('entered_sum')}>{measurementSummarySorting.attribute === 'entered_sum' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'exited_sum' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Wysiadło<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('exited_sum')}>{measurementSummarySorting.attribute === 'exited_sum' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'total_exchange' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Łączna<br></br>wymiana<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('total_exchange')}>{measurementSummarySorting.attribute === 'total_exchange' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'arrival_difference' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Różnica<br></br>przyjazd<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('arrival_difference')}>{measurementSummarySorting.attribute === 'arrival_difference' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'departure_difference' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Różnica<br></br>odjazd<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('departure_difference')}>{measurementSummarySorting.attribute === 'departure_difference' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className={measurementSummarySorting.attribute === 'accuracy' ? "job-working-details-table-header-cell job-working-details-table-header-cell-sorted" : "job-working-details-table-header-cell"}></div><span className="job-working-details-table-span job-working-details-column-2">Dokładność<br></br>pomiaru<br></br><button className="table-sorting-button-2" onClick={() => sortMeasurementSummaryTable('accuracy')}>{measurementSummarySorting.attribute === 'accuracy' ? (measurementSummarySorting.direction === 'descending' ? <span>&#x25be;</span>: <span>&#x25b4;</span>) : <span>&#x25B8;</span>}</button></span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data<br></br>pomiaru</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {outputMeasurementSummaryData.map((train, index) =>
                                                    <tr key={index} className={train.measurements.length > 0 && train.measurements.some(item => item.job_type === 'check') ? "train-row-checked" : "train-row-not-checked"}>
                                                        <th>{train.measurements.length > 0 ? <input onChange={selectMeasurementRow} checked={!isMeasuredTrainSelected(train.train_id)} type='checkbox' value={train.train_id}></input> : "-"}</th>
                                                        <th className="table-no-wrap">{formatTrainNumber(train.train_number)}</th>
                                                        <th>{train.relation}</th>
                                                        <th>{train.arrival_time ? train.arrival_time : "-"}</th>
                                                        <th>{train.departure_time ? train.departure_time : "-"}</th>
                                                        <th>{train.platform ? train.platform : "b/d"}</th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.full_name}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.job_number}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{getJobType(measurement.job_type)}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.entered_sum !== null && measurement.entered_sum !== undefined ? measurement.entered_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.exited_sum !== null && measurement.exited_sum !== undefined ? measurement.exited_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.entered_sum !== null && measurement.entered_sum !== undefined && measurement.exited_sum !== null && measurement.exited_sum !== undefined && (measurement.entered_sum + measurement.exited_sum > 0) ? measurement.entered_sum + measurement.exited_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.arrival_time && measurement.arrival_time) ? calculateTimeDifference(train.arrival_time, measurement.arrival_time) : "-"}</p>
                                                            ) : "-"}
                                                        </th>  
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.departure_time && measurement.departure_time) ? calculateTimeDifference(train.departure_time, measurement.departure_time) : "-"}</p>
                                                            ) : "-"}
                                                        </th> 
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{getAccuracy(measurement.accuracy)}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.measurement_date}</p>
                                                            ) : "-"}
                                                        </th>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table> 
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                {appLayer === 500 && 
                    <div className="job-assignment-panel-outer-container">
                        {updatedJobsData.length === 0 && 
                            <div className="waiting-wrapper">
                                {searching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!searching && 
                                    <>
                                        <p className="waiting-message">Brak danych w bazie</p>
                                        <button className='user-top-panel-button' onClick={()=>getJobsData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {updatedJobsData.length > 0 &&
                        <>
                            <div className="top-panel-admin-filters-container">
                                {uniqueJobsDays.length > 0 && 
                                    <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                        <p className="admin-selection-filter-label">Data pomiarów</p>
                                        <div className="job-selection-filter-dates-wrapper">
                                            <select
                                                onChange={(e) => onJobsDateChange(e)}
                                                value={dateJobsFilters.job_start_date}
                                                name={'job_start_date'}
                                                className={"filter-select"}
                                            >
                                                {startJobsDates.map((date, index) => (
                                                <option key={index} value={date}>{formatDate(date)}</option>
                                                ))}
                                            </select>
                                            <span className="job-selection-filter-date-separator">-</span>
                                            <select
                                                onChange={(e) => onJobsDateChange(e)}
                                                value={dateJobsFilters.job_end_date}
                                                name={'job_end_date'}
                                                className={"filter-select"}
                                            >
                                                {endJobsDates.map((date, index) => (
                                                <option key={index} value={date}>{formatDate(date)}</option>
                                                ))}
                                            </select>
                                            <button className="filter-single-remove-button" onClick={() => setDateJobsFilters({job_start_date: dateRange.min, job_end_date: dateRange.max})}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                        </div>
                                    </div>
                                }
                                {uniqueJobsStations.length > 0 && 
                                    <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                        <p className="admin-selection-filter-label">Punkt</p>
                                        <div className="job-selection-filter-dates-wrapper"> 
                                            <select
                                                onChange={onJobsStationChange}
                                                value={jobsStationFilter}
                                                name={'job_station_id'}
                                                className={"filter-select"}
                                            >
                                                <option value="">Wszystkie</option>
                                                {filteredJobsStations.map((station, index) => (
                                                <option key={index} value={station.station_id}>{station.station_name}</option>
                                                ))}
                                            </select>
                                            <button className="filter-single-remove-button" onClick={() => setJobsStationFilter('')}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                        </div>
                                    </div>
                                }
                                {uniqueJobsUsers.length > 0 && 
                                    <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                        <p className="admin-selection-filter-label">Pomiarowiec</p>
                                        <div className="job-selection-filter-dates-wrapper"> 
                                            <select
                                                onChange={onJobUserChange}
                                                value={jobsUserFilter}
                                                name={'job_user_full_name'}
                                                className={"filter-select"}
                                            >
                                                <option value="">Wszyscy</option>
                                                {filteredJobsUsers.map((user, index) => (
                                                <option key={index} value={user}>{user}</option>
                                                ))}
                                            </select>
                                            <button className="filter-single-remove-button" onClick={() => setJobsUserFilter('')}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                        </div>
                                    </div>
                                }
                            
                                {uniqueJobsScheduleDays.length > 0 && 
                                    <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                        <p className="admin-selection-filter-label">Data wydania</p>
                                        <div className="job-selection-filter-dates-wrapper"> 
                                            
                                            <select
                                                onChange={(e) => onScheduleJobsDateChange(e)}
                                                value={scheduleDateJobsFilters}
                                                name={'job_start_date'}
                                                className={"filter-select"}
                                            >
                                                <option value="">Wszystkie</option>
                                                {filteredJobsScheduleDays.map((date, index) => (
                                                <option key={index} value={date}>{formatDate(date)}</option>
                                                ))}
                                            </select>
                                            <button className="filter-single-remove-button" onClick={() => setScheduleDateJobsFilters('')}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                        </div>
                                    </div>
                                }
                                <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                    <p className="admin-selection-filter-label">Rodzaj zadania</p>
                                    <div className="job-selection-filter-dates-wrapper"> 
                                        
                                        <select
                                            onChange={onjobStatusFilterChange}
                                            value={jobStatusFilter.job_type}
                                            name={'job_type'}
                                            className={"filter-select"}
                                        >
                                            <option value={''}>Wszystkie</option>
                                            {filteredJobsTypes.map((type, index) => (
                                                <option key={index} value={type.type_value}>{type.type_name}</option>
                                            ))}
                                        </select>
                                        <button className="filter-single-remove-button" onClick={() => setJobStatusFilter({...jobStatusFilter, job_type: ''})}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                    </div>
                                </div>
                                <div className="job-selection-filter-wrapper admin-selection-filter-wrapper">
                                    <p className="admin-selection-filter-label">Stan wykonania</p>
                                    <div className="job-selection-filter-dates-wrapper"> 
                                        <select
                                            onChange={onjobStatusFilterChange}
                                            value={jobStatusFilter.job_status}
                                            name={'job_status'}
                                            className={"filter-select"}
                                        >
                                            <option value={''}>Wszystkie</option>
                                            {filteredJobsStatuses.map((status, index) => (
                                                <option key={index} value={status.status_value}>{status.status_name}</option>
                                            ))}
                                        </select>
                                        <button className="filter-single-remove-button" onClick={() => setJobStatusFilter({...jobStatusFilter, job_status: ''})}><span className="filter-single-remove-button-span">&#10006;</span></button>
                                    </div>
                                </div>
                            </div>
                            <div id="filter-main-remove-button-wrapper">
                                <button className="filter-remove-button" onClick={() => clearAllFilters()}><span className="filter-remove-button-span">Wyczyść wszystkie filtry &#10006;</span></button>
                            </div>
                            <div className="job-working-middle-outer-wrapper">
                                <div id="admin-table-wrapper-4" ref={jobTableRef} className="admin-table-wrapper">
                                    <table className="job-working-details-table">
                                        <thead>
                                            <tr>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Numer<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data<br></br>pomiaru</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Punkt /<br></br>Pociąg</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pracownik</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Czas<br></br>przepracowany</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Czas<br></br>zaraportowany</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Liczba<br></br>etapów</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Ukończ.<br></br>etapy</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Postęp<br></br>[ % ]</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Średnia<br></br>na pociąg</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Typ<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data<br></br>wydania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Otwórz<br></br>w widoku<br></br>pracownika</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pokaż<br></br>szczegóły</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredJobsData.length > 0 && filteredJobsData.map((job, index) =>
                                                <tr key={index}>
                                                    <td>{job.job_number}</td>
                                                    <td>{formatDate(job.recording_date)}</td>
                                                    <td>{job.station_name ? job.station_name : (job.train_number + ": " + job.first_station_name + " - " + job.last_station_name)}</td>
                                                    <td>{job.full_name}</td>
                                                    <td>{job.work_hours ? job.work_hours : "00:00:00"}</td>
                                                    <td>{job.reported_work_hours ? job.reported_work_hours : "-"}</td>
                                                    <td>{job.stages}</td>
                                                    <td>{job.completed_stages ? job.completed_stages : "0"}</td>
                                                    <td>{((job.completed_stages/job.stages)*100).toFixed(2)}</td>
                                                    <td>{job.completed_stages ? (formatTime((job.work_time/job.completed_stages).toFixed())) : "-"}</td>
                                                    <td>{job.type === "normal" ? "zwykłe" : "kontrolne"}</td>
                                                    <td>{formatDate(job.created_on)}</td>
                                                    <td><button className="job-working-train-icon" onClick={() => openJob(job)}><Icon8/></button></td>
                                                    <td><button className="job-working-train-icon" onClick={() => showDetailedJob(job)}><Icon3/></button></td>
                                                </tr>
                                            )}
                                            {filteredJobsData.length === 0 &&
                                                <tr>
                                                    <th colSpan={12}>Żadne zadanie nie spełnia podanych kryteriów</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        </>}
                    </div>
                }
                {appLayer === 550 && 
                    <div className="job-assignment-panel-outer-container">
                        {detailedJobData.length === 0 && 
                            <div className="waiting-wrapper">
                                <>
                                    <p className="waiting-message">Generowanie zestawienia</p>
                                    <Gear/>
                                </>
                            </div>
                        }
                        {detailedJobData.length > 0 &&
                            <>
                                <div className="job-assignment-panel-info-inner-wrapper">
                                    <p className="job-assignment-panel-text job-assignment-important">Zadanie {detailedJobInfo.job_number} - {detailedJobInfo.type === 'station' ? 'stacja ' + detailedJobInfo.station_name : 'pociąg ' + detailedJobInfo.train_number} {formatDate(detailedJobInfo.recording_date)}<button id="admin-back-button" onClick={() => cancelDetailedJobView()}>Cofnij<span>&#10140;</span></button></p>
                                    {cameraSlideList.length > 0 && <button className="job-additional-button job-additional-button-bottom" onClick={() => setModal({...modal, show: true, cameras_photos_panel: true})}>Podgląd kamer</button>}
                                    
                                </div>
                                <div className="user-panel-function-buttons-wrapper-2">
                                    <button className={detailedJobData.filter(obj => obj.measurements.length > 0).every(obj => selectedJobTrains.includes(obj.train_id)) ? "admin-function-button admin-function-button-disabled" : "admin-function-button"} onClick={() => prepareJob('station', true, 'check')}>Utwórz zadanie kontrolne</button>
                                </div>
                                <div className="admin-working-middle-outer-wrapper">
                                    <div id="admin-table-wrapper-4-5" className="admin-table-wrapper">
                                        <table className="job-working-details-table">
                                            <thead>
                                                <tr>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span job-working-details-column">Wybór{detailedJobData.some(obj => obj.measurements.length > 0) && <input onChange={inverseJobMeasuredSelection} checked={isJobMeasuredTrainChecked} type='checkbox' value={''}></input>}</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Numer<br></br>pociągu</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Relacja</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Godzina<br></br>przyjazdu</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Godzina<br></br>odjazdu</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wsiadło</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wysiadło</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Łączna<br></br>wymiana</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Różnica<br></br>przyjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Różnica<br></br>odjazd</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Dokładność<br></br>pomiaru</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Data<br></br>wpisu</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Godzina<br></br>wpisu</span></th>
                                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pracownik</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailedJobData.map((train, index) =>
                                                    <tr key={index} className={train.measurements.length > 0 && train.measurements.some(item => item.job_type === 'check') ? "train-row-checked" : "train-row-not-checked"}>
                                                        <th>{train.measurements.length > 0 ? <input onChange={selectJobMeasurementRow} checked={!isJobMeasuredTrainSelected(train.train_id)} type='checkbox' value={train.train_id}></input> : "-"}</th>
                                                        <th className="table-no-wrap">{formatTrainNumber(train.train_number)}</th>
                                                        <th>{train.relation}</th>
                                                        <th>{train.arrival_time ? train.arrival_time : "-"}</th>
                                                        <th>{train.departure_time ? train.departure_time : "-"}</th>
                                                        <th>{train.platform ? train.platform : "b/d"}</th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.entered_sum !== null && measurement.entered_sum !== undefined ? measurement.entered_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.exited_sum !== null && measurement.exited_sum !== undefined ? measurement.exited_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.entered_sum !== null && measurement.entered_sum !== undefined && measurement.exited_sum !== null && measurement.exited_sum !== undefined && (measurement.entered_sum + measurement.exited_sum > 0) ? measurement.entered_sum + measurement.exited_sum : "-"}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.arrival_time && measurement.arrival_time) ? calculateTimeDifference(train.arrival_time, measurement.arrival_time) : "-"}</p>
                                                            ) : "-"}
                                                        </th>  
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.departure_time && measurement.departure_time) ? calculateTimeDifference(train.departure_time, measurement.departure_time) : "-"}</p>
                                                            ) : "-"}
                                                        </th> 
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{getAccuracy(measurement.accuracy)}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(measurement.measurement_date).split(" ")[0]}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(measurement.measurement_date).split(" ")[1]}</p>
                                                            ) : "-"}
                                                        </th>
                                                        <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                                <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.full_name}</p>
                                                            ) : "-"}
                                                        </th>
                                                    </tr>
                                                )}
                                                
                                            </tbody>
                                        </table> 
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                {appLayer === 600 && 
                    <div id="user-view-app-inner-container-2">
                        <div className="admin-panel-main-menu-container-2">
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(610)}>
                                <p className="admin-panel-main-menu-title">Pracownicy</p>
                                <Icon8/>
                            </div>
                            <div className="admin-panel-main-menu-wrapper" onClick={()=>setAppLayer(620)}>
                                <p className="admin-panel-main-menu-title">Pociągi</p>
                                <Icon1/>
                            </div>
                        </div>
                        
                    </div>
                }
                {appLayer === 610 &&
                    <div id="user-view-app-inner-container">
                        {usersWithJobs.length === 0 &&
                            <div className="waiting-wrapper">
                                {userWithJobsSearching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!userWithJobsSearching && 
                                    <>
                                        <p className="waiting-message">Brak pracowników z aktywnymi zadaniami</p>
                                        <button className='user-top-panel-button' onClick={()=>getUsersWithJobsData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {usersWithJobs.length > 0 && 
                            <div className={selectedWorkerView === "" ? "user-view-selection-filter-wrapper" : "user-view-selection-filter-wrapper user-view-selection-filter-wrapper-border"}>
                                <div className="user-view-selection-filter-inside-wrapper">
                                    <p className="admin-selection-filter-label">Pracownik:</p>
                                    <div>
                                        <select
                                            onChange={onSelectedWorkerViewChange}
                                            value={selectedWorkerView}
                                            name={'selected-user-view'}
                                            className={"filter-select"}
                                        >
                                            {selectedWorkerView === '' && <option value={''}>wybierz</option>}
                                            {usersWithJobs.map((user, index) => (
                                                <option key={index} value={user.personal_id}>{user.full_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {activeJob && 
                                    <div className="user-view-top-panel-wrapper">
                                        <button className="user-top-panel-button" onClick={() => exitJob()}>Zmień zadanie</button>
                                    </div>
                                }
                            </div>
                        }
                        {selectedWorkerView !== '' && 
                            <>
                                {!activeJob && <div id="user-view-middle-wrapper">
                                    {!userViewSearching && updatedUserViewJobs.length > 0 && 
                                        <div className="job-selection-outer-wrapper">
                                            <div className="job-selection-top-panel-wrapper">
                                                {userViewJobDates.length > 0 && 
                                                    <div className="job-selection-filter-wrapper">
                                                        <p className="admin-selection-filter-label">Data pomiarów</p>
                                                        <select
                                                            onChange={onUserViewJobFilterChange}
                                                            value={jobFilters.job_date}
                                                            name='job_date'
                                                            className="filter-select"
                                                            id="user-job-date"
                                                        >
                                                            <option value=''>Wszystkie</option>
                                                            {userViewJobDates.map((date, index) => (
                                                            <option key={index} value={date}>{date}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                }
                                                {userViewJobStations.length > 0 &&
                                                    <div className="job-selection-filter-wrapper">
                                                        <p className="admin-selection-filter-label">Nazwa stacji</p>
                                                        <select
                                                            onChange={onUserViewJobFilterChange}
                                                            value={jobFilters.job_station_name}
                                                            name='job_station_name'
                                                            className="filter-select"
                                                            id="user-job-station"
                                                        >
                                                            <option value=''>Wszystkie</option>
                                                            {userViewJobStations.map((station, index) => (
                                                            <option key={index} value={station}>{station}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                }
                                                <button className="clear-job-filters-button" onClick={() => setJobFilters({job_station_name: '', job_date: ''})}>Pokaż wszystkie</button>
                                            </div>
                                            <div className="admin-working-middle-outer-wrapper">
                                            <div id="admin-table-wrapper-5" className="admin-table-wrapper">
                                                <table className="job-working-details-table">
                                                    <thead>
                                                        <tr>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">ID</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Punkt/Pociąg</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Data</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Liczba<br></br>pociągów<br></br>/ stacji</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Liczba odcz.<br></br>pociągów<br></br>/ stacji</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">% wykonania</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Informacje<br></br>dodatkowe</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Czas pracy /<br></br>zaraportowany<br></br>czas pracy</span></th>
                                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Wybierz</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredUserViewJobs.map((job, index) =>
                                                            <tr key={index} className={job.stages === job.completed_stages ? "job-completed-row" : ""}>
                                                                <td>{job.job_number}</td> 
                                                                <td>{job.station_name ? job.station_name : job.first_station_name + ' \u2014 ' + job.last_station_name}</td>
                                                                <td>{formatDate(job.recording_date)}</td>
                                                                <td>{job.stages}</td>
                                                                <td>{job.completed_stages ? job.completed_stages : '0'}</td>
                                                                <td>{!job.completed_stages ? "0" : ((job.completed_stages/job.stages)*100).toFixed(2)}</td>
                                                                <td>{job.comments ? job.comments : 'brak'}</td>
                                                                <td><span className="table-reported-time-span">{job.work_hours}</span>{job.reported_work_hours && <span className="table-reported-time-span">{job.reported_work_hours}</span>}</td>
                                                                <td><button className="job-selection-button" onClick={() => activateJob(job.job_number)}>&#10000;</button></td>
                                                            </tr>
                                                        )}
                                                        {filteredUserViewJobs.length === 0 && 
                                                            <tr>
                                                                <td colSpan={9}>Brak zadań spełniających wybrane kryteria</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table> 
                                            </div>   
                                            </div>     
                                        </div>
                                    }
                                    {updatedUserViewJobs.length === 0 && 
                                        <div id="user-view-info-wrapper">
                                            {userViewSearching && 
                                                <>
                                                    <p className="waiting-message">Pobieranie danych</p>
                                                    <Gear/>
                                                </>
                                            }
                                            {!userViewSearching && 
                                                <>
                                                    <p className="waiting-message">Brak zadań dla wybranego pracownika</p>
                                                </>
                                            }

                                        </div>
                                    }
                                </div>
                                }
                                {activeJob && 
                                    <div id="job-working-outer-container" className={(activeTrain.id || activeStation) ? "" : "job-working-outer-extended"}>
                                        <h1 className="section-title">Zadanie nr {activeJob.job_number}</h1>
                                        <div className="job-working-top-outer-wrapper">
                                            <div className="job-working-top-inner-wrapper">
                                                <p className="job-working-top-text">Data pomiarów: <span className="job-info-important">{formatDate(activeJob.recording_date)}</span></p>
                                                {activeJob.station_name && <p className="job-working-top-text">Badany punkt: <span className="job-info-important">{activeJob.station_name}</span></p>}
                                                {activeJob.station_name && activeJob.stages !== null && activeJob.stages !== undefined && <p className="job-working-top-text">Liczba pociągów: <span className="job-info-important">{activeJob.stages}</span></p>}
                                                {activeJob.train_number && <p className="job-working-top-text">Numer pociągu: <span className="job-info-important">{activeJob.train_number}</span></p>}
                                                {activeJob.first_station_name && activeJob.last_station_name && <p className="job-working-top-text">Relacja: <span className="job-info-important">{activeJob.first_station_name + " - " + activeJob.last_station_name}</span></p>}
                                            </div>
                                            <div className="job-working-top-2-inner-wrapper">
                                                {cameraSlides.length > 0 && <button className="job-additional-button" onClick={() => setModal({...modal, show: true, cameras_photos: true})}>Podgląd kamer</button>}
                                                {ftpList.length > 0 && <button className="job-additional-button" onClick={() => setModal({...modal, show: true, ftp_info: true})}>Pobierz filmy</button>}
                                                {activeJob.train_id && mergedUserViewTrains.length > 0 && (mergedUserViewTrains.find(u => u.train_id === activeJob.train_id).photos.length > 0) && <button onClick={() => showPhotos(activeJob.train_id)} className="job-additional-button">Zobacz zdjęcia</button>}
                                            </div>
                                        </div>
                                        {activeJob.station_name && 
                                            <div className="job-working-middle-outer-wrapper">
                                                <div className="job-working-table-wrapper">
                                                    <table className="job-working-details-table">
                                                        <thead>
                                                            <tr>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pociąg</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Relacja</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wsiadło</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wysiadło</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zdjęcia</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmiany w<br></br>rozkładzie</span></th>
                                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Wyświetl</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mergedUserViewTrains.map((train, index) =>
                                                                <tr key={index} className={`${getClassNames(train.train_id, train.measurement)}`}>
                                                                    <td>{train.train_number}</td>
                                                                    <td>{train['platform_number_'+train.station_index] ? train['platform_number_'+train.station_index] : "b/d"}</td>
                                                                    <td>{train['lane_number_'+train.station_index] ? train['lane_number_'+train.station_index] : "b/d"}</td>
                                                                    <td>{train['arrival_hour_'+train.station_index] ? train['arrival_hour_'+train.station_index] : "-"}</td>
                                                                    <td>{train['departure_hour_'+train.station_index] ? train['departure_hour_'+train.station_index] : "-"}</td>
                                                                    <td>{train.first_station_name + " - " + train.last_station_name}</td>
                                                                    <td>{train.measurement && train.measurement.entered_sum}</td>
                                                                    <td>{train.measurement && train.measurement.exited_sum}</td>
                                                                    <td>{train.photos[0] ? <button className="job-working-train-icon" onClick={() => showPhotos(train.train_id)}><Icon3/></button> : "brak"}</td>
                                                                    <td>{train.delay ? train.delay : "-"}</td>
                                                                    <td><button className="job-working-train-icon" onClick={() => activateTrain(train.id)}><Icon1/></button></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table> 
                                                </div>
                                            </div>
                                        }
                                        {activeJob.train_number && <div className="job-working-middle-outer-wrapper">
                                            <div className="job-working-table-wrapper">
                                                <table className="job-working-details-table">
                                                    <thead>
                                                        <tr>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Stacja</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wsiadło</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wysiadło</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmiany w<br></br>rozkładzie</span></th>
                                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Analizuj</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mergedUserViewTrains[0] && mergedUserViewTrains[0].stations.map((station, index) =>
                                                            <tr key={index} className={`${getStationClassNames(station[0])}`}>
                                                                <td>{station[1]}</td>
                                                                <td>{station[4] ? station[4] : "b/d"}</td>
                                                                <td>{station[5] ? station[5] : "b/d"}</td>
                                                                <td>{station[2] ? station[2] : '-'}</td>
                                                                <td>{station[3] ? station[3] : '-'}</td>
                                                                {station[7] === false && <td>-</td>}
                                                                {station[7] === true && <td>
                                                                    {mergedUserViewTrains[0].measurement.map((measurement, i) =>
                                                                        <p key={i} className="station-measurements-line">
                                                                            {measurement.station_id === station[0] && <span>{measurement.entered_sum}</span>}
                                                                        </p>
                                                                    )}
                                                                </td>}
                                                                {station[7] === false && <td>-</td>}
                                                                {station[7] === true && <td>
                                                                    {mergedUserViewTrains[0].measurement.map((measurement, i) =>
                                                                        <p key={i} className="station-measurements-line">
                                                                            {measurement.station_id === station[0] && <span>{measurement.exited_sum}</span>}
                                                                        </p>
                                                                    )}
                                                                </td>}
                                                                <td>{station[6] ? station[6] : '-'}</td>
                                                                <td>{station[7] === true ? <button className="job-working-train-icon" onClick={() => activateSelectedStation(station[0])}><Icon1/></button> : "-"}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table> 
                                            </div>
                                        </div>}
                                        {(activeTrain.id || activeStation) && 
                                            <div className="job-working-bottom-outer-wrapper">
                                                <div className="measurement-title">
                                                    {activeTrain.id && <h2 className="section-title">Pociąg {activeTrain.train_number}<br></br>{activeTrain.first_station_name} - {activeTrain.last_station_name}</h2>}
                                                    {activeStation && <h2 className="section-title">Stacja {getStationName()}</h2>}
                                                </div>
                                                <div className="entered-exited-form-outer-container">
                                                    <div className="entered-exited-form-middle-wrapper">
                                                        <div className="entered-exited-form-inner-wrapper">
                                                            <p className="measuring-form-label measuring-form-label-fixed-width">Wsiadło</p>
                                                            <div className="entered-exited-outer-line-wrapper">
                                                                <div className="entered-exited-line-wrapper">
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_1'}
                                                                        name={'entered_1'}
                                                                        value={measurementFormData.entered_1 !== undefined ? measurementFormData.entered_1 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_2'}
                                                                        name={'entered_2'}
                                                                        value={measurementFormData.entered_2 !== undefined ? measurementFormData.entered_2 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_3'}
                                                                        name={'entered_3'}
                                                                        value={measurementFormData.entered_3 !== undefined ? measurementFormData.entered_3 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="entered-exited-line-wrapper">
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_4'}
                                                                        name={'entered_4'}
                                                                        value={measurementFormData.entered_4 !== undefined ? measurementFormData.entered_4 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_5'}
                                                                        name={'entered_5'}
                                                                        value={measurementFormData.entered_5 !== undefined ? measurementFormData.entered_5 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'entered_6'}
                                                                        name={'entered_6'}
                                                                        value={measurementFormData.entered_6 !== undefined ? measurementFormData.entered_6 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="entered-exited-sum-wrapper">
                                                                <input 
                                                                    className="input-number input-number-sum" 
                                                                    type="number" 
                                                                    min="0" 
                                                                    id={'entered_sum'}
                                                                    name={'entered_sum'}
                                                                    value={measurementFormSummary.entered_sum !== '' ? measurementFormSummary.entered_sum : ""}
                                                                    readOnly
                                                                />
                                                                <p className="measuring-form-label">&sum;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="entered-exited-form-middle-wrapper">
                                                        <div className="entered-exited-form-inner-wrapper">
                                                            <p className="measuring-form-label measuring-form-label-fixed-width">Wysiadło</p>
                                                            <div className="entered-exited-outer-line-wrapper">
                                                                <div className="entered-exited-line-wrapper">
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_1'}
                                                                        name={'exited_1'}
                                                                        value={measurementFormData.exited_1 !== undefined ? measurementFormData.exited_1 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_2'}
                                                                        name={'exited_2'}
                                                                        value={measurementFormData.exited_2 !== undefined ? measurementFormData.exited_2 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_3'}
                                                                        name={'exited_3'}
                                                                        value={measurementFormData.exited_3 !== undefined ? measurementFormData.exited_3 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                <div className="entered-exited-line-wrapper">
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_4'}
                                                                        name={'exited_4'}
                                                                        value={measurementFormData.exited_4 !== undefined ? measurementFormData.exited_4 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_5'}
                                                                        name={'exited_5'}
                                                                        value={measurementFormData.exited_5 !== undefined ? measurementFormData.exited_5 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                    <input 
                                                                        className="input-number" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        id={'exited_6'}
                                                                        name={'exited_6'}
                                                                        value={measurementFormData.exited_6 !== undefined ? measurementFormData.exited_6 : ""}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="entered-exited-sum-wrapper">
                                                                <input 
                                                                    className="input-number input-number-sum" 
                                                                    type="number" 
                                                                    min="0" 
                                                                    id={'exited_sum'}
                                                                    name={'exited_sum'}
                                                                    value={measurementFormSummary.exited_sum !== "" ? measurementFormSummary.exited_sum : ""}
                                                                    readOnly
                                                                />
                                                                <p className="measuring-form-label">&sum;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hours-form-outer-wrapper">
                                                    <div className="hours-form-additional-wrapper">
                                                        <div className="hours-form-wrapper">
                                                            <p className="measuring-form-label measuring-form-label-fixed-width-2">Godzina przyjazdu</p>
                                                            <div className="hours-inner-wrapper">
                                                                
                                                                <input
                                                                    className="input-number input-time"
                                                                    type="time"
                                                                    id="arrival"
                                                                    name="arrival"
                                                                    value={jobTime.arrival}
                                                                    onChange={handleTimeChange}
                                                                    step="60" 
                                                                    readOnly
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="hours-form-wrapper">
                                                            <p className="measuring-form-label measuring-form-label-fixed-width-2">Godzina odjazdu</p>
                                                            <div className="hours-inner-wrapper">
                                                                
                                                                <input
                                                                    className="input-number input-time"
                                                                    type="time"
                                                                    id="departure"
                                                                    name="departure"
                                                                    value={jobTime.departure}
                                                                    onChange={handleTimeChange}
                                                                    step="60" 
                                                                    readOnly
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="form-slider-outer-wrapper">
                                                    <div id="slider-info-parent">
                                                        <p className="measuring-form-label" onMouseOver={() => setSliderInfo(true)} onMouseOut={() => setSliderInfo(false)}>Dokładność pomiaru<span id="info-slider-span">&#9432;</span></p>
                                                        {sliderInfo &&
                                                        <div id="slider-info-child">
                                                            <p className="slider-info-child-paragraph">99% - możliwy błąd 1 osoby na 100</p>
                                                            <p className="slider-info-child-paragraph">95% - możliwy błąd 1 osoby na 25</p>
                                                            <p className="slider-info-child-paragraph">90% - możliwy błąd 1 osoby na 10</p>
                                                            <p className="slider-info-child-paragraph">80% - możliwy błąd 2 osoby na 10</p>
                                                            <p className="slider-info-child-paragraph">Uwaga - wymagana jest dokładność pomiaru na poziomie 99-95%.</p>
                                                            <p className="slider-info-child-paragraph">Wyższy poziom błędu jest dopuszczalny tylko jeśli jakość obrazu nie pozwala na dokładne policzenie osób.</p>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="form-slider-inner-wrapper">
                                                        <input
                                                            id="accuracy-slider"
                                                            type="range"
                                                            min={1}
                                                            max={5}
                                                            step={1}
                                                            value={accuracy === '' ? 1 : accuracy}
                                                            onWheel={(e) => e.target.blur()}
                                                            readOnly
                                                        />
                                                        <div className="form-slider-info-outer-wrapper">
                                                            <div className="form-slider-info-inner-wrapper">
                                                                <span className="form-slider-span">&#10072;</span>
                                                                <span className="form-slider-span form-slider-span-lower">&lt;80%</span>
                                                            </div>
                                                            <div className="form-slider-info-inner-wrapper">
                                                                <span className="form-slider-span">&#10072;</span>
                                                                <span className="form-slider-span form-slider-span-lower">80%</span>
                                                            </div>
                                                            <div className="form-slider-info-inner-wrapper">
                                                                <span className="form-slider-span">&#10072;</span>
                                                                <span className="form-slider-span form-slider-span-lower">90%</span>
                                                            </div>
                                                            <div className="form-slider-info-inner-wrapper">
                                                                <span className="form-slider-span">&#10072;</span>
                                                                <span className="form-slider-span form-slider-span-lower">95%</span>
                                                            </div>
                                                            <div className="form-slider-info-inner-wrapper">
                                                                <span className="form-slider-span">&#10072;</span>
                                                                <span className="form-slider-span form-slider-span-lower">99%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-additional-fields-outer-wrapper">
                                                    <p className="measuring-form-label">Komentarz</p>
                                                    <div className="form-additional-fields-inner-wrapper">
                                                        <textarea 
                                                            id="add_comment"
                                                            type="text"
                                                            name="add_comment"
                                                            placeholder="komentarz do pomiaru"
                                                            className="cam-form-field cam-form-field-textarea"
                                                            onChange={handleComment}   
                                                            value={additionalComment}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
                {appLayer === 620 &&
                    <div id="user-view-app-inner-container">
                        {trainListSelectionData.length === 0 &&
                            <div className="waiting-wrapper">
                                {trainViewSearching && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!trainViewSearching && 
                                    <>
                                        <p className="waiting-message">Brak pociągów w bazie danych</p>
                                        <button className='user-top-panel-button' onClick={()=>getTrainViewData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {trainListSelectionData.length > 0 &&
                            <div className={selectedTrainListView.train === "" ? "user-view-selection-filter-wrapper" : "user-view-selection-filter-wrapper user-view-selection-filter-wrapper-border"}>
                                <div className="user-view-selection-filter-inside-wrapper train-list-view-wrapper">
                                    <div className="train-list-view-inner-wrapper">
                                        <p className="admin-selection-filter-label admin-selection-filter-label-nowrap">Grupa pociągów:</p>
                                        <div>
                                            <select
                                                onChange={onSelectedTrainViewChange}
                                                value={selectedTrainListView.trains_group}
                                                name={'trains_group'}
                                                className={"filter-select"}
                                            >
                                                {selectedTrainListView.trains_group === '' && <option value={''}>wybierz</option>}
                                                {trainListSelectionData.map((group, index) => (
                                                    <option key={index} value={group.name}>{group.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <span className="train-list-separator">&#10230;</span>
                                    <div className="train-list-view-inner-wrapper">
                                        <p className="admin-selection-filter-label">Pociąg:</p>
                                        <div>
                                            <select
                                                onChange={onSelectedTrainViewChange}
                                                value={selectedTrainListView.train}
                                                name={'train'}
                                                className={"filter-select"}
                                                disabled={selectedTrainListView.trains_group === ''}
                                            >
                                                {selectedTrainListView.train === '' && <option value={''}>wybierz</option>}
                                                {trainListSelectionData.filter(item => item.name === selectedTrainListView.trains_group).length > 0 && trainListSelectionData.filter(item => item.name === selectedTrainListView.trains_group)[0].data.map((train, index) => (
                                                    <option key={index} value={train}>{train}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> 
                                </div>                
                            </div>
                        }
                        {trainViewDetailedSearching.active &&
                            <div className="waiting-wrapper waiting-wrapper-2">
                                {trainViewDetailedSearching.search && 
                                    <>
                                        <p className="waiting-message">Pobieranie danych</p>
                                        <Gear/>
                                    </>
                                }
                                {!trainViewDetailedSearching.search && 
                                    <>
                                        <p className="waiting-message">Wystąpił problem z pobraniem danych</p>
                                        <button className='user-top-panel-button' onClick={()=>getTrainListDetailedData()}>Sprawdź ponownie</button>
                                    </>
                                }
                            </div>
                        }
                        {outputTrainList.length > 0 && 
                            <div className="job-working-middle-outer-wrapper">
                                <div className="train-view-title-wrapper">
                                    <p className="job-assignment-panel-text job-assignment-important">Pociąg {outputTrainList[0].train_number} relacji {outputTrainList[0].output[0].station_name + " - " + outputTrainList[0].output[outputTrainList[0].output.length -1].station_name}</p>
                                </div>
                                <div id="admin-table-wrapper-5-5" className="admin-table-wrapper">
                                    <table className="job-working-details-table">
                                        <thead>
                                            <tr>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Numer<br></br>stacji</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Nazwa<br></br>stacji</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Planowy<br></br>przyjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Planowy<br></br>odjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Daty<br></br>pomiaru</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Numer<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Rodzaj<br></br>zadania</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Sprawdzany<br></br>dzień</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Suma<br></br>wsiadających</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Suma<br></br>wysiadających</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Różnica<br></br>przyjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Różnica<br></br>odjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odczytujący<br></br>pracownik</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {outputTrainList[0].output.length > 0 && outputTrainList[0].output.map((train, index) =>
                                                <tr key={index}>
                                                    <th>{index+1}</th>
                                                    <th>{train.station_name}</th>
                                                    <th>{train.arrival_hour ? train.arrival_hour : "-"}</th>
                                                    <th>{train.departure_hour ? train.departure_hour : "-"}</th>
                                                    <th>{train.platform_number ? train.platform_number : "b/d"}</th>
                                                    <th>{train.lane_number ? train.lane_number : "b/d"}</th>
                                                    <th>{train.recording_dates.length > 0 ? train.recording_dates.map((date, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{formatDate(date)}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.job_number}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.job_type === 'normal' ? 'zwykłe' : 'kontrolne'}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{formatDate(measurement.recording_date)}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(measurement.entered_sum !== null && measurement.entered_sum !== undefined) ? measurement.entered_sum : "-"}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) => 
                                                        <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(measurement.exited_sum !== null && measurement.exited_sum !== undefined) ? measurement.exited_sum : "-"}</p>
                                                    ) : "-"}
                                                    </th>
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                            <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.arrival_hour && measurement.arrival_time) ? calculateTimeDifference(train.arrival_hour, measurement.arrival_time) : "-"}</p>
                                                        ) : "-"}
                                                    </th>  
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                            <p key={i} className="station-measurements-line station-measurements-line-nowrap">{(train.departure_hour && measurement.departure_time) ? calculateTimeDifference(train.departure_hour, measurement.departure_time) : "-"}</p>
                                                        ) : "-"}
                                                    </th> 
                                                    <th>{train.measurements.length > 0 ? train.measurements.map((measurement, i) =>
                                                            <p key={i} className="station-measurements-line station-measurements-line-nowrap">{measurement.user_full_name}</p>
                                                        ) : "-"}
                                                    </th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            {modal.show &&
                <div className="modal-overlay" onClick={() => closeModal()}>
                    {/* Dodawanie użytkownika */}
                    {modal.add_user && !modal.error &&
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Dodaj użytkownika</h2>
                            </div>
                            <div className="modal-body">
                                <div className="form-line-outer-wrapper">
                                <div className="form-line-column-wrapper">
                                <div className="form-line-wrapper">
                                    <span className="form-label">Nazwa użytkownika:</span>
                                    <input 
                                        className="user-form"
                                        id="add_username"
                                        type="text"
                                        onChange={userAddFormChange}
                                        name="add_username"
                                        value={userAddForm.add_username}
                                        autoComplete="new-password" 
                                    />
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Hasło:</span>
                                    <input 
                                        className="user-form"
                                        id="add_password"
                                        type="password"
                                        onChange={userAddFormChange}
                                        name="add_password"
                                        value={userAddForm.add_password}
                                        autoComplete="new-password" 
                                    />
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Imię i nazwisko:</span>
                                    <input 
                                        className="user-form"
                                        id="full_name"
                                        type="text"
                                        onChange={userAddFormChange}
                                        name="full_name"
                                        value={userAddForm.full_name}
                                    />
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Mail:</span>
                                    <input 
                                        className="user-form"
                                        id="mail"
                                        type="text"
                                        onChange={userAddFormChange}
                                        name="mail"
                                        value={userAddForm.mail}
                                    />
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Telefon:</span>
                                    <input 
                                        className="user-form"
                                        id="phone"
                                        type="text"
                                        onChange={userAddFormChange}
                                        name="phone"
                                        value={userAddForm.phone}
                                    />
                                </div>
                                </div>
                                <div className="vertical-separator"></div>
                                <div className="form-line-column-wrapper">
                                <div className="form-line-wrapper">
                                    <span className="form-label">Ocena:</span>
                                    <div className="user-form-slider-wrapper">
                                        <span className="form-slider-label">1</span>
                                        <input
                                            type="range"
                                            min={1}
                                            max={5}
                                            step={1}
                                            id="rating"
                                            name="rating"
                                            value={userAddForm.rating}
                                            onChange={userAddFormChange}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <span className="form-slider-label">5</span>
                                    </div>
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Stawka:</span>
                                    <input 
                                        className="user-form"
                                        id="hour_rate"
                                        type="text"
                                        onChange={userAddFormChange}
                                        name="hour_rate"
                                        value={userAddForm.hour_rate}
                                    />
                                </div>
                                <div className="form-line-wrapper">
                                    <span className="form-label">Komentarz:</span>
                                    <textarea 
                                        className="user-comment"
                                        id="comment"
                                        onChange={userAddFormChange}
                                        name="comment"
                                        value={userAddForm.comment}
                                    />
                                </div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button" onClick={() => createUser()}>Utwórz użytkownika</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Zmiana hasła */}
                    {modal.change_password && !modal.error &&
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Zmień hasło</h2>
                            </div>
                            <div className="modal-body">
                                <div className="form-line-wrapper">
                                    <p className="modal-info-text">Podaj nowe hasło dla użytkownika {passwordChange.full_name}:</p>
                                </div>
                                <div className="form-line-wrapper">
                                    <input 
                                        className="user-form user-form-margin"
                                        id="new_password"
                                        type="password"
                                        onChange={newPasswordChange}
                                        name="new_password"
                                        value={passwordChange.new_password}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button user-top-panel-button-fixed" onClick={() => changePassword()}>Potwierdź</button>
                                    <button className="user-top-panel-button user-top-panel-button-fixed user-top-panel-button-red" onClick={() => closeModal()}>Anuluj</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Zmiana statusu użytkownika */}
                    {modal.change_status && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">{userToDeactivate.new_status === 'active' ? "Aktywacja użytkownika" : "Deaktywacja użytkownika"}</h2>
                            </div>
                            <div className="modal-body">
                                <p className="modal-info-text modal-info-text-margin">Czy na pewno chcesz {userToDeactivate.new_status === 'active' ? "aktywować" : "deaktywować"} użytkownika {userToDeactivate.full_name}?</p>
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button user-top-panel-button-fixed" onClick={() => changeUserActivation()}>{userToDeactivate.new_status === 'active' ? "Aktywuj" : "Deaktywuj"}</button>
                                    <button className="user-top-panel-button user-top-panel-button-fixed user-top-panel-button-red" onClick={() => closeModal()}>Anuluj</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Dodawanie zadania */}
                    {modal.create_job && !modal.error &&
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Utwórz zadanie</h2>
                            </div>
                            <div className="modal-body">
                                <div className="form-line-wrapper">
                                    <span className="form-label">Czy na pewno utworzyć zadanie i przydzielić<br></br>je pracownikowi {chosenUser.first_name + " " + chosenUser.surname}?</span>
                                </div>
                                <div className="form-line-wrapper form-line-wrapper-extended">
                                    <textarea 
                                        id="job_comments"
                                        type="text"
                                        name="job_comments"
                                        placeholder="Opcjonalny komentarz do zadania"
                                        className="cam-form-field cam-form-field-textarea"
                                        onChange={handleComment}   
                                        value={jobComment}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button user-top-panel-button-fixed" onClick={() => createJob()}>Utwórz zadanie</button>
                                    <button className="user-top-panel-button user-top-panel-button-fixed user-top-panel-button-red" onClick={() => closeModal()}>Anuluj</button>

                                </div>
                            </div>
                        </div>
                    }
                    {/* Błędy */}
                    {modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header modal-header-error">
                                <h2 className="modal-title">Błąd</h2>
                            </div>
                            <div className="modal-body">
                                {formErrors.length > 0 && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">Formularz zawiera błędy:</p>
                                        <ul className="modal-error-ul">
                                            {formErrors.map((error, index) => (
                                                <li className="modal-error-list" key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                {modalErrorMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">{modalErrorMessage}</p>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button user-top-panel-button-red" onClick={() => closeErrorModal()}>OK</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Pokazywanie zdjęć */}
                    {modal.show_photo && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <h2 className="modal-title">Podgląd zdjęć</h2>
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                            </div>
                            <div className="modal-body">
                                {photosToShow.length > 0 &&
                                    <div className="photo-preview-wrapper">
                                        <Carousel>
                                            {photosToShow.map((image, index) =>
                                                <CarouselItem key={index} name={image.name}>
                                                    <div className="carousel-image-wrapper">
                                                        <img onClick={() => handleMarkerGallery(index)} className="camera-image" referrerPolicy="no-referrer" src={image.url} alt="pociąg"/>
                                                    </div>
                                                </CarouselItem>
                                            )}
                                        </Carousel>
                                        <Lightbox
                                            open={openModal}
                                            index={imageIndex}
                                            close={() => setOpenModal(false)}
                                            slides={viewModalSlides}
                                            plugins={[Thumbnails, Zoom]}
                                            zoom={{
                                                maxZoomPixelRatio: 10,
                                                scrollToZoom: true
                                            }}
                                        />
                                    </div>
                                }
                            </div>       
                        </div>
                    }
                    {/* Podgląd kamer */}
                    {modal.cameras_photos && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                <h2 className="modal-title">Podgląd kamer</h2>
                            </div>
                            <div className="modal-body">
                                {cameraSlides.length > 0 && <div className="photo-preview-wrapper">
                                    <Carousel>
                                        {cameraSlides.map((image, index) =>
                                            <CarouselItem key={index} name={image.name}>
                                                <div className="carousel-image-wrapper">
                                                    <img onClick={() => handleMarkerGallery(index)} className="camera-image" referrerPolicy="no-referrer" src={image.url} alt="kamera"/>
                                                </div>
                                            </CarouselItem>
                                        )}
                                    </Carousel>
                                    <Lightbox
                                        open={openModal}
                                        index={imageIndex}
                                        close={() => setOpenModal(false)}
                                        slides={modalSlides}
                                        plugins={[Thumbnails, Zoom]}
                                        zoom={{
                                            maxZoomPixelRatio: 10,
                                            scrollToZoom: true
                                        }}
                                    />
                                </div>}
                            </div>
                        </div>
                    }
                    {/* Podgląd kamer - panel realizacji */}
                    {modal.cameras_photos_panel && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                <h2 className="modal-title">Informacje o filmach</h2>
                            </div>
                            <div className="modal-body">
                                {ftpDirectoriesList.length > 0 && <div className="ftp-nested-list-wrapper">
                                    <div>Filmy z badanego punktu znajdują się w:
                                        <ul className="ftp-nested-list ftp-nested-list-padding">
                                            {ftpDirectoriesList.map((ftp, index) => (
                                                <li key={index}>{ftp.disk}/{ftp.path}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>}
                                {cameraSlideList.length > 0 && <div className="photo-preview-wrapper">
                                    <Carousel>
                                        {cameraSlideList.map((image, index) =>
                                            <CarouselItem key={index} name={image.name}>
                                                <div className="carousel-image-wrapper">
                                                    <img onClick={() => handleMarkerGallery(index)} className="camera-image" referrerPolicy="no-referrer" src={image.url} alt="kamera"/>
                                                </div>
                                            </CarouselItem>
                                        )}
                                    </Carousel>
                                    <Lightbox
                                        open={openModal}
                                        index={imageIndex}
                                        close={() => setOpenModal(false)}
                                        slides={modalSlides}
                                        plugins={[Thumbnails, Zoom]}
                                        zoom={{
                                            maxZoomPixelRatio: 10,
                                            scrollToZoom: true
                                        }}
                                    />
                                </div>}
                            </div>
                        </div>
                    }
                    {/* Podgląd ftp */}
                    {modal.ftp_info && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                <h2 className="modal-title">Pobieranie z FTP</h2>
                            </div>
                            <div className="modal-body">
                                <div id="ftp-info-container">
                                    <ol>
                                        <li>Pobierz, a następnie zainstaluj darmowego klienta FTP - Filezilla z <a className="ftp-link" target="_blank" rel="noreferrer" href="https://filezilla-project.org/download.php">tego adresu</a></li>
                                        <li>Połącz się z serwerem za pomocą poniższych danych:
                                            <ul className="ftp-nested-list">
                                                <li>Host - <span id="ftp_host" className="ftp-span">rubika.myftp.org</span><button className="ftp-button" onClick={() => copyText('ftp_host')}>{buttonStatus.ftp_host ? 'skopiowano' : 'kopiuj'}</button></li>
                                                <li>Użytkownik - <span id="ftp_user" className="ftp-span">filmy</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button className="ftp-button" onClick={() => copyText('ftp_user')}>{buttonStatus.ftp_user ? 'skopiowano' : 'kopiuj'}</button></li>
                                                <li>Hasło - <span id="ftp_password" className="ftp-span">997analiza</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button className="ftp-button" onClick={() => copyText('ftp_password')}>{buttonStatus.ftp_password ? 'skopiowano' : 'kopiuj'}</button></li>
                                            </ul>
                                        </li>
                                        <li>Filmy z badanego punktu znajdziesz w poniższych lokalizacjach:
                                            <ul className="ftp-nested-list">
                                                {ftpList.map((ftp, index) => (
                                                    <li key={index}>{ftp.disk}/{ftp.path}</li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>Jeśli potrzebujesz informacji jak korzystać z klienta FTP <a className="ftp-link" target="_blank" rel="noreferrer" href="https://analiza.badaniaruchu.pl/photos/ftp.jpg">zajrzyj tutaj</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Dodawanie listy pociągów */}
                    {modal.import_trains && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Importuj listę pociągów</h2>
                            </div>
                            <div className="modal-body">
                                <textarea 
                                    id="train_list"
                                    type="text"
                                    name="train_list"
                                    placeholder="Wklej listę pociągów"
                                    className="train-list-import-area"
                                    onChange={handleTrainListImport}   
                                    value={trainListImport}
                                />
                            </div>
                            {importListMessage && 
                                <div className="modal-info-wrapper">
                                    <p className="modal-info-text modal-info-error">{importListMessage}</p>
                                </div>
                            }
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className={trainListImport !== '' ? "user-top-panel-button user-top-panel-button-fixed" : "user-top-panel-button user-top-panel-button-fixed user-top-panel-button-disabled"} onClick={() => importTrainList()}>Importuj</button>
                                    <button className="user-top-panel-button user-top-panel-button-fixed user-top-panel-button-red" onClick={() => closeModal()}>Anuluj</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Informacje */}
                    {modal.info && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Informacja</h2>
                            </div>
                            <div className="modal-body">
                                {modalMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text modal-success-text">{modalMessage}</p>
                                        {modalSpin && <Gear/>}
                                    </div>
                                }                   
                            </div>
                            {!modalSpin && <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    {modalMessage && <button className="user-top-panel-button" onClick={() => closeModal()}>OK</button>}
                                </div>
                            </div>}
                        </div>
                    }
                </div>
            }
        </div>
    );
};